import { fetch_resource } from "@/composables/fetch_resource";

export function get(resource) {
  return fetch_resource(resource, "GET");
}

export function post(resource, body) {
  return fetch_resource(resource, "POST", null, body);
}

export function put(resource, body) {
  return fetch_resource(resource, "PUT", null, body);
}

export function del(resource) {
  return fetch_resource(resource, "DELETE");
}
