const state = {
  user_id: null,
  firstname: null,
  lastname: null,
  nickname: null,
};

const mutations = {
  setUser: (state, user) => {
    state.user_id = user.person_id;
    state.firstname = user.firstname;
    state.lastname = user.lastname;
    state.nickname = user.nickname;
  },
};
const getters = {
  displayName: (state) => (state.nickname ? state.nickname : state.firstname),
  fullName: (state, getters) => `${getters.displayName} ${state.lastname}`,
  userId: (state) => state.user_id,
  getUser: (state) => ({
    person_id: state.user_id,
    firstname: state.firstname,
    lastname: state.lastname,
    nickname: state.nickname,
  }),
};
export default {
  state,
  getters,
  mutations,
};
