const state = {
  acad_level: null,
  selected_level: null,
  student_levels: [],
  student_max_acad_lvl: null,
};

const getters = {
  getAcadLevel: (state) => state.acad_level,
  getStudentMaxLevel: (state) => state.student_max_acad_lvl,
  getStudentLevels: (state, getters, rootState) => {
    let student_lvls = [];
    let levels = state.student_levels.sort(
      (a, b) => a.acad_level.value - b.acad_level.value
    );
    // console.log(levels);
    let has_selected = false;
    rootState.lookup_data.acad_levels.forEach((lvl) => {
      // console.log(lvl.acad_level_id);
      if (lvl.value >= 0) {
        let level = levels.filter(
          (l) => l.acad_level.acad_level_id === lvl.acad_level_id
        );
        // console.log(level.length);
        if (level.length > 1) {
          // console.log(level);
          level = level.reduce((most_recent, next) => {
            if (
              new Date(next.term.start_date) >
              new Date(most_recent.term.start_date)
            ) {
              return next;
            }
            return most_recent;
          });
          // console.log(level);
        } else {
          // console.log(level);
          if (
            (lvl.value == 0 && level.length === 0) || // skip llm if  no llm found in all levels
            (lvl.value == 1 && student_lvls.findIndex((l) => l.value == 0) > -1) // skip 1L if there is a LLM present
          ) {
            // console.log(level);
            return; //continue;
          }
          level =
            level.length > 0
              ? level[0]
              : {
                  acad_level: lvl,
                }; // No longer an array...
          // console.log(level);
        }
        // console.log(level);
        let selected = false;
        selected =
          level.acad_level?.acad_level_id === state.acad_level?.level_id;

        if (!has_selected) {
          if (!selected) {
            selected = level.current ?? false;
          } else {
            has_selected = true;
          }
        }
        // console.log(selected);

        const l = {
          level_id: lvl.acad_level_id,
          level: lvl.level,
          value: lvl.value,
          current: level.current ?? false,
          student_at_level_id: level.student_at_level_id,
          selected: selected,
          term: level.term,
          disabled: !level.student_at_level_id,
        };
        student_lvls.push(l);
      }
    });
    window.sessionStorage.setItem(
      "student_levels",
      JSON.stringify(student_lvls)
    );
    // console.log(student_lvls);
    return student_lvls;
  },
};

const mutations = {
  update_student_levels(state, levels) {
    state.student_levels = levels;
  },
  update_level(state, level) {
    state.acad_level = level;
  },
};

const actions = {
  fetchStudentLevels: ({ commit, dispatch }) => {
    return dispatch("fetchResource", {
      method: "GET",
      resource: "student/levels",
    }).then((data) => {
      if (data.length <= 1) {
        localStorage.setItem("student_status", "new");
      } else if (localStorage.getItem("student_status") !== "graduated") {
        let current = data.find((level) => level.current);
        if (current.acad_level.level === "1L") {
          localStorage.setItem("student_status", "new");
        } else {
          localStorage.setItem("student_status", "existing");
        }
      }
      const current = data.find((lvl) => lvl.current);
      commit("update_selected_term", current.term);
      commit("update_level", current);
      commit("update_student_levels", data);
    });
  },
  update_acad_level: ({ commit }, profile) => {
    commit("update_selected_term", profile.term);
    commit("update_level", profile);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
