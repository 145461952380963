<template>
  <div class="flex-auto self-center pl-8 hidden md:block">
    <span class="text-indigo lg:text-3xl w-auto">
      <span>{{ greeting }}</span>
      <span class="font-bold">{{
        displayName ? ` ${displayName}!` : "!"
      }}</span>
    </span>
  </div>
</template>

<script>
import { TimeOfDay } from "@/composables/date_utils";
import { mapGetters } from "vuex";

export default {
  name: "TopbarGreeting",
  computed: {
    ...mapGetters(["displayName"]),
    greeting() {
      return TimeOfDay();
    },
  },
};
</script>

<style scoped></style>
