import { get } from "@/composables/httpUtil";

const groupPages = {
  adm: ["/*"],
  fac: [
    "/admin",
    "/admin/fieldworks/*",
    "/admin/mentors/*",
    "/admin/students/*",
    "/admin/pairings/*",
  ],
  saj: [""],
  sra: [
    "/admin",
    "/admin/students/*",
    "/admin/mentors/*",
    "/admin/fieldworks/*",
    "/admin/pairings/*",
  ],
  stu: [
    "/",
    "/profile*",
    "/mentor*",
    "/plan*",
    "/fieldworks*",
    "help",
    "/summer*",
  ],
  men: ["/mentor/*"],
};

export function getToken() {
  const userData = sessionStorage.getItem("user");
  if (userData === undefined) {
    userData;
  } else {
    return JSON.parse(userData);
  }
}

export function setToken(user) {
  //we stringify the json object because local & session storage cannot store objects
  sessionStorage.setItem("user", JSON.stringify(user));
}

export function getJwtGroups() {
  let token = getToken();

  if (!token) {
    return false;
  }

  return token.grp;
}

export function checkAdmin() {
  let groups = getJwtGroups();
  return groups
    ? groups.includes("adm") || groups.includes("fac") || groups.includes("saj")
    : false;
}

export function isStu() {
  let groups = getJwtGroups();
  return groups ? groups.includes("stu") : false;
}

export function isAdm() {
  let groups = getJwtGroups();
  return groups ? groups.includes("adm") : false;
}

export function isMen() {
  let groups = getJwtGroups();
  return groups ? groups.includes("men") : false;
}

export function isSra() {
  let groups = getJwtGroups();
  return groups ? groups.includes("sra") : false;
}

export function isFac() {
  let groups = getJwtGroups();
  return groups ? groups.includes("fac") && !groups.includes("adm") : false;
}

export function isSaj() {
  let groups = getJwtGroups();
  return groups ? groups.includes("saj") && !groups.includes("adm") : false;
}

export function isMasquerade() {
  //if we are masquerading as a student masq should have a value
  const token = getToken();
  return token ? token.masq !== undefined : false;
}

export function unsetToken() {
  localStorage.clear();
  sessionStorage.clear();
}

export function checkMasq() {
  let token = getToken();
  if (!token) {
    return false;
  }
  return token.masq;
}

export function getAWSCredentials() {
  let token = getToken();
  if (!token) {
    return false;
  }
  return {
    access: token.access,
    secret: token.secret,
    session: token.token,
  };
}

export function checkJwtGroups(to) {
  let groups = getJwtGroups();
  // console.log(groups)
  let found = false;
  // console.log("to:" + to);
  groups.forEach(function (grp) {
    // console.log("grp:" + grp);
    groupPages[grp].forEach(function (path) {
      // console.log("path:" + path);
      // console.log(to.match(path));
      if (to.match(path)) {
        // console.log("Approved Path");
        found = true;
      }
    });
  });
  if (found) {
    return true;
  }
  // console.log("Restricted Path");
  return false;
}

export function getJwtStatus() {
  let token = getToken();
  if (!token) {
    return false;
  }

  //let payload = JSON.parse(atob(token.split(".")[1]));
  let payload = token;
  let iat = new Date(payload.iat * 1000);
  let now = new Date();
  let elapsed = (now - iat) / 1000 / 60;
  // console.log("Elapsed: " + elapsed);
  // console.log(process.env.VUE_APP_TOKEN_TIMEOUT);
  if (elapsed > process.env.VUE_APP_TOKEN_TIMEOUT) {
    // console.log("Token Expired");
    return false;
  }
  return true;
}

export function getUserName() {
  const token = getToken();
  if (!token) {
    return;
  }
  const payload = token;
  return {
    person_id: payload.sub,
    firstname: payload.first,
    lastname: payload.last,
    nickname: payload.nick,
  };
}

export function refreshUserTokenInformation() {
  get("user").then((data) => {
    setToken(data);
  });
}
