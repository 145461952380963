/*eslint-disable*/
// const plan = {
//     plan_loading: false,
//     ppdp_id : null,
//     ethics_statement : null,
//     comments : null,
//     debrief_topics : null,
//     mn_confidentiality_cert : null,
//     mn_prof_conduct : null,
//     misconduct_disclaimer : null,
//     status : null,
//     student_at_level_id : null,
//     reviewing_mentor_id : null,
//     ppdp_mtg_fieldwork_entry_id : null,
//     experiences : null,
// };

const state = {
    plans: {},
    plan_loading: false,
}

const getters = {};

const mutations = {
    update_plan: (state, data) => {
        if (Array.isArray(data)) {
            data.forEach((plan) => {
                let local_plan = {}
                let mentor_id = null
                Object.entries(plan).forEach(([key, value]) => {
                    local_plan[key] = value ? value : null;
                    if (key === "reviewing_mentor_id") {
                        mentor_id = value;
                    }
                });
                state.plans[mentor_id] = local_plan
            })
        } else {
            state.plans[data.reviewing_mentor_id] = data
        }
    }
};

const actions = {
    fetchPlanResource: async ({commit, dispatch, state, rootState}) => {
        state.plan_loading = true;
        return dispatch('fetchResource', {
                method: "GET",
                resource: "student/ppdp",
                params: {student_at_level: rootState.level_store.acad_level.student_at_level_id}
            }
        )
            .then(data => {
                if (data.length) {
                    commit('update_plan', data)
                }
            })
            .then(() => state.plan_loading = false)
    },
    postPlanResource: async ({commit, dispatch, state}, plan) => {
        state.plan_loading = true;
        return dispatch('fetchResource', {
            method: "POST",
            resource: "student/ppdp",
            body: plan
        })
            .then(data => commit('update_plan', data))
            .then(() => state.plan_loading = false)
    },
    putPlanResource: async ({commit, dispatch, state}, plan) => {
        state.plan_loading = true;

        return dispatch('fetchResource', {
            method: "PUT",
            resource: `student/ppdp/${plan.ppdp_id}`,
            body: plan
        })
            .then(data => commit('update_plan', data))
            .then(() => state.plan_loading = false)
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
