<template>
  <button
    :data-cy="'button-' + text"
    :class=classes
    :disabled="is_disabled"
  >
    {{ text }}
  </button>
</template>

<script>
//---------------------------------------------------------------------------------------------------------------------------------------------
// Purpose: Provide a consistant button styling look and feel
// Inputs :
//      text - String - text that should be displayed on the button
//---------------------------------------------------------------------------------------------------------------------------------------------

export default {
  name: "Button",
  data() {
    return {
      classes: "",
    };
  },
  mounted() {
    if (this.size === "small") {
      this.classes =
        "px-2 py-1 inline-block rounded-full hover:bg-dark-purple bg-purple text-white disabled:bg-light-gray disabled:text-slate-gray";
    } else {
      this.classes = this.primary
        ? " px-4 py-2 inline-block rounded-full hover:bg-dark-purple bg-purple text-white disabled:bg-light-gray disabled:text-slate-gray"
        : " px-4 py-2 inline-block rounded-full hover:bg-dark-purple hover:text-white bg-white border-purple border text-dark-purple disabled:bg-light-gray";
    }
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    primary: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      required: false,
    },
  },
};
</script>
