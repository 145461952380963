<template>
  <div>
    <div class="secondhead-text pb-1.5">
      Mentor{{ mentors.length > 1 ? "s" : "" }} you're paired with
    </div>
    <loading-spinner v-if="mentors_loading" />
    <div v-else>
      <div v-for="(mentor, key) in mentors" :key="`mentor_${key}`">
        <div class="card-block">
          <div class="text-base font-bold">
            {{ mentor.title }} {{ mentor.firstname }} {{ mentor.lastname }}
            {{ mentor.suffix }}
          </div>
          <div class="">
            {{
              mentor.employer.length
                ? mentor.employer.find((emp) => emp.current).employer
                    .employer_name
                : ""
            }}
          </div>
        </div>
        <div class="card-block">
          <div class="text-purple-link">
            {{
              mentor.phone.length > 0
                ? mentor.phone.find((phone_number) => {
                    return phone_number.is_public;
                  }).phone_number
                : "No phone number found."
            }}
          </div>
          <div v-if="mentor?.email.length" class="text-purple-link">
            <a href="#">{{
              mentor.email.find((email_address) => {
                return email_address.is_public;
              }).email_address
            }}</a>
          </div>
        </div>
      </div>
      <div>
        <div class="text-purple-link font-bold">
          <router-link to="/mentor">View Details</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import { mapState } from "vuex";
export default {
  name: "MyMentor",
  components: { LoadingSpinner },
  props: {
    mentors: {
      type: Array,
      required: true,
    },
  },
  computed: {
    current_employer() {
      return this.mentors;
    },
    ...mapState({
      mentors_loading: (state) => state.student.mentors_loading,
    }),
    // student_mentors() {
    //   return this.mentors.filter(m => !m.is_pairing_closed)
    // }
  },
};
</script>

<style scoped>
.card-block {
  @apply mb-1 pb-1.5;
}
</style>
