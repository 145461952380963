const genID = () => {
  const idPart = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };

  return (
    idPart() +
    idPart() +
    "-" +
    idPart() +
    "-3" +
    idPart().substr(0, 2) +
    "-" +
    idPart() +
    "-" +
    idPart() +
    idPart() +
    idPart()
  ).toLowerCase();
};

/**
 * emits a CustomEvent to trigger a Toast alert
 * @param level the alert level. choose from success, warn, error, and info (info being the default)
 * @param message the message to display in the toast alert
 * @param details an object of additional details. NOTE: if you want your toast to only be visible for a given time include `{timeout: Number of milliseconds you want your toast visible for}`
 */
export function emitAlert(level, message, details) {
  if (!["error", "warn", "success", "info"].includes(level)) {
    throw Error("Invalid Alert Type");
  }
  const event = new CustomEvent("alertEvent", {
    detail: {
      id: genID(),
      level: level,
      message: message,
      details: details,
    },
  });
  window.dispatchEvent(event);
}
