import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";
import VueHtmlToPaper from "@/composables/HTMLToPaper";

import {
  faUserSecret,
  faHome,
  faUserCircle,
  faCalendarAlt,
  faBalanceScaleLeft,
  faClipboardList,
  faCommentDots,
  faBell,
  faChevronDown,
  faChevronUp,
  faReply,
  faCheck,
  faBan,
  faCheckCircle,
  faMap,
  faFlag,
  faCompass,
  faEdit,
  faUsers,
  faClock,
  faComments,
  faInfoCircle,
  faChevronCircleLeft,
  faChevronCircleRight,
  faSearch,
  faSpinner,
  faEye,
  faEyeSlash,
  faExclamationTriangle,
  faGripLines,
  faTimes,
  faSort,
  faSortDown,
  faSortUp,
  faLink,
  faHourglass,
  faPlus,
  faTrash,
  faArrowLeft,
  faArrowRight,
  faTheaterMasks,
  faMailBulk,
  faPhone,
  faEnvelope,
  faBuilding,
  faGlobe,
  faPenFancy,
  faMinus,
  faLightbulb,
  faUnlink,
  faMagic,
  faCaretDown,
  faCaretUp,
  faTimesCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "./index.css";
import tokenUtils from "@/composables/tokenUtils";

library.add(
  faUserSecret,
  faHome,
  faUserCircle,
  faCalendarAlt,
  faBalanceScaleLeft,
  faClipboardList,
  faCommentDots,
  faBell,
  faChevronDown,
  faChevronUp,
  faReply,
  faCheck,
  faBan,
  faCheckCircle,
  faMap,
  faFlag,
  faCompass,
  faEdit,
  faUsers,
  faClock,
  faComments,
  faInfoCircle,
  faChevronCircleLeft,
  faChevronCircleRight,
  faSearch,
  faSpinner,
  faEyeSlash,
  faEye,
  faExclamationTriangle,
  faGripLines,
  faTimes,
  faSort,
  faSortDown,
  faSortUp,
  faLink,
  faPlus,
  faHourglass,
  faTrash,
  faArrowLeft,
  faArrowRight,
  faTheaterMasks,
  faPhone,
  faEnvelope,
  faBuilding,
  faGlobe,
  faPenFancy,
  faMinus,
  faLightbulb,
  faUnlink,
  faMagic,
  faTheaterMasks,
  faMailBulk,
  faCaretDown,
  faCaretUp,
  faTimesCircle,
  faExclamationCircle
);

createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(store)
  .use(router)
  .use(VueHtmlToPaper)
  .use(tokenUtils)
  .mount("#app");
