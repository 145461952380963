import { emitAlert } from "@/composables/alerts";

export async function fetch_resource(resource, method, params, body) {
  const queryParams = params
    ? "?" +
      Object.entries(params)
        .map((param) => `${param[0]}=${param[1]}`)
        .join("&")
    : "";
  let options = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Request-Method": method.toUpperCase(),
      "Access-Control-Request-Headers": "Content-Type",
    },
    credentials: "include",
    mode: "cors",
  };
  if (body) {
    options["body"] = JSON.stringify(body);
  }

  const url = `${process.env.VUE_APP_BASE_API_URL}${
    process.env.VUE_APP_LOCAL // this is the only way I've been able to get concurrent request to work locally
      ? `:${
          Math.floor(
            Math.random() * Number(process.env.VUE_APP_MAX_PORT_RANGE)
          ) + Number(process.env.VUE_APP_MIN_PORT)
        }/`
      : ""
  }${resource}${queryParams}`;
  return await fetch(url, options).then(responseValid).catch(responseValid);
}

export const responseValid = (response) => {
  if (response.ok) {
    return response.json();
  }
  console.error("error in network communication");

  // TODO 401/403 logic here
  emitAlert(
    "error",
    "There was an error while accessing your data. Please try again later.",
    { response: response, timeout: 10000 }
  );
  throw Error(response);
};
