import { parseDate } from "@/composables/date_utils";

const milestone_statuses = {
  OVERDUE: "OVERDUE",
  UPCOMING: "UP_COMING",
  COMPLETE: "COMPLETE",
  TODO: "TODO",
};

let approved_entries = (rootState) => {
  return rootState.form_data.entries.filter((entry) => {
    return entry.event_status === "APPROVED";
  });
};

let checkRequirement = (
  milestone,
  rootState,
  milestone_index,
  milestone_list
) => {
  let fieldwork = approved_entries(rootState);
  if (milestone.requirement.entry_type.includes("Plan Document")) {
    let planStatuses = Object.values(rootState.plan_data.plans).map(
      (plan) => plan.status
    );
    return (
      planStatuses.length &&
      planStatuses.every((status) => status === "COMPLETE")
    );
  } else if (
    milestone.requirement.entry_type.includes("Point of Contact") &&
    milestone.requirement.entry_type.length === 1
  ) {
    let poc_id = rootState.lookup_data.fieldwork_types.find(
      (type) => type.fieldwork_type_name === "Point of Contact"
    ).fieldwork_log_type_id;
    return (
      fieldwork.filter(
        (entry) =>
          entry.fieldwork_type_id === poc_id &&
          parseDate(entry.added, true).getTime() <= milestone.due_date.getTime()
      ).length > 0
    );
  } else if (
    milestone.requirement.entry_type.includes("PPDP Meeting") &&
    milestone.requirement.entry_type.length === 1
  ) {
    let ppdp = rootState.form_data.progress_items.find(
      (item) => item.name === "Plan (PPDP)"
    );
    let is_added_later = fieldwork.filter(
      (entry) =>
        entry.fieldwork_type_name.includes("PPDP Meeting") &&
        parseDate(entry.added, true) < milestone.due_date
    );
    return (
      ppdp.progress.complete === ppdp.progress.total &&
      is_added_later.length > 0
    );
  } else if (
    milestone.requirement.entry_type.includes("Year-End Meeting") &&
    milestone.requirement.entry_type.length === 1
  ) {
    let ye_id = rootState.lookup_data.fieldwork_types.find(
      (type) => type.fieldwork_type_name === "Year-End Meeting"
    ).fieldwork_log_type_id;
    return (
      fieldwork.filter(
        (entry) =>
          entry.fieldwork_type_id === ye_id &&
          parseDate(entry.added, true).getTime() <=
            parseDate(milestone.due_date).getTime()
      ).length > 0
    );
  } else if (
    milestone.requirement.entry_type.includes("Progress Report") &&
    milestone.requirement.entry_type.length === 1
  ) {
    let progress_met = true;
    rootState.form_data.progress_items.forEach((item) => {
      if (item.progress.complete !== item.progress.total) {
        progress_met = false;
      }
    });
    return progress_met;
  } else if (
    milestone.requirement.entry_type.includes("Mid-Year Report") &&
    milestone.requirement.entry_type.length === 1
  ) {
    const debrief = fieldwork.filter(
      (entry) =>
        entry.fieldwork_type_name.includes("Debrief") &&
        parseDate(entry.added, true) < milestone.due_date
    );

    const exp_field_id = rootState.lookup_data.fieldwork_types
      .find((entry) => entry.fieldwork_type_name === "Experience")
      .fields.find((entry) => entry.name === "Experience ID").field_id;

    const distinct_experiences = fieldwork
      .filter((entry) => {
        return entry.fieldwork_type_name.includes("Experience") &&
          parseDate(entry.added, true) <= milestone.due_date;
      })
      .reduce((distinct_experiences, fw) => {
        const exp_id = fw.fields.find(
          (field) => field.fieldwork_log_field_id === exp_field_id
        );
        if (exp_id){
          if (!distinct_experiences.includes(exp_id.value)) {
            distinct_experiences.push(exp_id.value);
          }
        }
        return distinct_experiences;
      }, []);

    return debrief.length >= 1 && distinct_experiences.length >= 2;
  } else {
    // Is a monthly fieldwork deadline
    const first_contact = milestone_list.find((ms) =>
      ms.description.includes("First Contact")
    );
    const previous_milestone =
      milestone.due_date.getMonth() === 9
        ? first_contact
        : milestone_list.find((ms) => ms.id === milestone.id - 1);
    let entry_requirements_met = {};
    let i = 0;
    for (i = 0; i < milestone.requirement.entry_type.length; i++) {
      const entry_type = milestone.requirement.entry_type[i];
      const req_count = milestone.requirement.entry_quantity[i];
      let entry_type_id = rootState.lookup_data.fieldwork_types.find(
        (type) => type.fieldwork_type_name === entry_type
      ).fieldwork_log_type_id;
      const act_count = fieldwork.filter((fw) => {
        const subDate = parseDate(fw.added, true).getTime();
        return (
          fw.fieldwork_type_id === entry_type_id &&
          subDate <= milestone.due_date.getTime() &&
          subDate >=
            (entry_type === "Experience"
              ? first_contact
              : previous_milestone
            ).due_date.getTime()
        );
      }).length;
      entry_requirements_met[entry_type] = req_count <= act_count;
    }

    return (
      (entry_requirements_met["Debrief"] ||
        entry_requirements_met["Point of Contact"]) &&
      entry_requirements_met["Experience"]
    );
  }
};

const state = {
  homepage_milestone_display_id: 11,
  fetching_milestones: false,
  student_milestones: [
    {
      id: 1,
      cy_id: "content-home-Milestone-first-contact",
      description: "First Contact with Mentor",
      due_date: null,
      status: "TODO",
      display: false,
      icon: require("@/../public/icons-1.3/icon-mentorreception.svg"),
      requirement: {
        entry_type: ["Point of Contact"],
        mentor_present: true,
        entry_quantity: [1],
      },
      requirement_met: false,
    },
    {
      id: 2,
      cy_id: "content-home-Milestone-ppdp",
      description: "My Plan (PPDP)",
      due_date: null,
      status: "TODO",
      display: false,
      icon: require("@/../public/icons-1.3/icon-yearendmeeting.svg"),
      requirement: {
        entry_type: ["Plan Document"],
        mentor_present: true,
        entry_quantity: [1],
      },
      requirement_met: false,
    },
    {
      id: 3,
      cy_id: "content-home-Milestone-ppdp_mtg",
      description: "PPDP Meeting Deadline",
      due_date: null,
      status: "TODO",
      display: false,
      icon: require("@/../public/icons-1.3/icon-yearendmeeting.svg"),
      requirement: {
        entry_type: ["PPDP Meeting"],
        mentor_present: true,
        entry_quantity: [1],
      },
      requirement_met: false,
    },
    {
      id: 4,
      cy_id: "content-home-Milestone-monthly-oct",
      description: "Monthly Fieldwork Deadline",
      due_date: null,
      status: "TODO",
      display: false,
      icon: require("@/../public/icons-1.3/user-circle.svg"),
      requirement: {
        entry_type: ["Point of Contact", "Debrief", "Experience"],
        mentor_present: false,
        entry_quantity: [1, 1, 1],
      },
      requirement_met: false,
    },
    {
      id: 5,
      cy_id: "content-home-Milestone-mid-year",
      description: "Mid-Year Fieldwork Deadline",
      due_date: null,
      status: "TODO",
      display: false,
      icon: require("@/../public/icons-1.3/icon-experiences.svg"),
      requirement: {
        entry_type: ["Mid-Year Report"],
        mentor_present: false,
        entry_quantity: [1],
      },
      requirement_met: false,
    },
    {
      id: 6,
      cy_id: "content-home-Milestone-monthly-jan",
      description: "Monthly Fieldwork Deadline",
      due_date: null,
      status: "TODO",
      display: false,
      icon: require("@/../public/icons-1.3/user-circle.svg"),
      requirement: {
        entry_type: ["Point of Contact", "Debrief", "Experience"],
        mentor_present: false,
        entry_quantity: [1, 1, 3],
      },
      requirement_met: false,
    },
    {
      id: 7,
      cy_id: "content-home-Milestone-first-feb",
      description: "Monthly Fieldwork Deadline",
      due_date: null,
      status: "TODO",
      display: false,
      icon: require("@/../public/icons-1.3/user-circle.svg"),
      requirement: {
        entry_type: ["Point of Contact", "Debrief", "Experience"],
        mentor_present: false,
        entry_quantity: [1, 1, 4],
      },
      requirement_met: false,
    },
    {
      id: 8,
      cy_id: "content-home-Milestone-eoy",
      description: "Year-End Fieldwork Deadline",
      due_date: null,
      status: "TODO",
      display: false,
      icon: require("@/../public/icons-1.3/icon-addentries.svg"),
      requirement: {
        entry_type: ["Progress Report"],
        mentor_present: false,
        entry_quantity: [1],
      },
      requirement_met: false,
    },
    {
      id: 9,
      cy_id: "content-home-Milestone-eoy-mtg",
      description: "Year-End Meeting Deadline",
      due_date: null,
      status: "TODO",
      display: false,
      icon: require("@/../public/icons-1.3/icon-yearendmeeting.svg"),
      requirement: {
        entry_type: ["Year-End Meeting"],
        mentor_present: false,
        entry_quantity: [1],
      },
      requirement_met: false,
    },
  ],
  milestone_steps: [
    {
      id: 1,
      header: "First Contact with Mentor",
      link: {
        path: "/fieldworks/add",
        text: "Add Fieldwork Entry",
      },
      steps: [
        {
          description: "Research your mentor.",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
        {
          description:
            "Contact your mentor to introduce yourself and arrange a date and time to discuss your  plan (PPDP).",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
        {
          description:
            "Send your mentor the introductory letter and resume that you prepared for your course assignment. ",
          lvls: ["2L", "3L"],
        },
        {
          description:
            "Add a fieldwork entry for your Point of Contact with your mentor.",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
      ],
      extra: [
        {
          text: "Missed the deadline?",
          button: "Contact your program director immediately.",
          path: "/help",
        },
      ],
    },
    {
      id: 2,
      header: "My Plan (PPDP)",
      link: {
        path: "/plan",
        text: "View Your Plan",
      },
      steps: [
        {
          description:
            " Follow up with mentor if necessary to schedule PPDP meeting.",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
        {
          description: "Draft your PPDP Document. ",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
        {
          description: "Email your mentor your PPDP draft. ",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
        {
          description:
            "Meet with your mentor and review your PPDP to talk about what experiences and debriefs you plan to do. ",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
        {
          description:
            "After your meeting with your mentor, revise your PPDP as necessary. ",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
        {
          description:
            "Submit your final PPDP, making sure to carefully review and complete the certifications if you have met the certifications. ",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
        {
          description:
            "After you submit your plan, log your PPDP meeting with your mentor.",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
      ],
      extra: [
        {
          text: "Need an extension?",
          button: "Contact your program director before the deadline.",
        },
        {
          text: "Missed the deadline?",
          button: "Contact your program director immediately.",
          path: "/help",
        },
      ],
    },
    {
      id: 3,
      header: "PPDP Meeting Deadline",
      link: {
        path: "/fieldworks/add",
        text: "Add fieldwork entry",
      },
      steps: [
        {
          description:
            "Make sure you have already  submitted your final plan (PPDP).",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
        {
          description:
            "Add a fieldwork entry for your PPDP Meeting with your mentor.",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
        {
          description:
            "Make sure to carefully review and complete the certifications you need to meet.",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
      ],
      extra: [
        {
          text: "Need an extension?",
          button: "Contact your program director before the deadline.",
        },
        {
          text: "Missed the deadline?",
          button: "Contact your program director immediately.",
          path: "/help",
        },
      ],
    },
    {
      id: 4,
      header: "Monthly Fieldwork Deadline",
      link: {
        path: "/fieldworks",
        text: "View Your Fieldwork",
      },
      steps: [
        {
          description:
            "Complete and add a fieldwork entry for a Debrief or Point of Contact with your mentor.",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
        {
          description:
            "Complete and add a fieldwork entry for an Experience with your mentor or another attorney or judge. ",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
        {
          description:
            "Correct any returned fieldwork entries and re-submit them for approval. ",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
      ],
      extra: [
        {
          text: "Missed the deadline?",
          button: "Contact your program director.",
          path: "/help",
        },
      ],
    },
    {
      id: 5,
      header: "Mid-Year Fieldwork Deadline",
      link: {
        path: "/fieldworks",
        text: "View Your Fieldwork",
      },
      steps: [
        {
          description:
            "Complete and add a fieldwork entry for at least one (1) Debrief with your mentor this semester. ",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
        {
          description:
            "Complete and add a fieldwork entry for at least two (2) Distinct Experiences with your mentor or another attorney or judge this semester. ",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
        {
          description:
            "Correct any returned fieldwork entries and re-submit them for approval. ",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
      ],
      extra: [
        {
          text: "Need an extension?",
          button: "Contact your program director before the deadline.",
          path: "/help",
        },
        {
          text: "Missed the deadline?",
          button: "Contact your program director.",
          path: "/help",
        },
      ],
    },
    {
      id: 6,
      header: "Monthly Fieldwork Deadline",
      link: {
        path: "/fieldworks",
        text: "View Your Fieldwork",
      },
      steps: [
        {
          description:
            "Complete and add a fieldwork entry for a Debrief or Point of Contact with your mentor.",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
        {
          description:
            "Complete and add a fieldwork entry for an Experience with your mentor or another attorney or judge. ",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
        {
          description:
            "Correct any returned fieldwork entries and re-submit them for approval. ",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
      ],
      extra: [
        {
          text: "Missed the deadline?",
          button: "Contact your program director.",
          path: "/help",
        },
      ],
    },
    {
      id: 7,
      header: "Monthly Fieldwork Deadline",
      link: {
        path: "/fieldworks",
        text: "View Your Fieldwork",
      },
      steps: [
        {
          description:
            "Complete and add a fieldwork entry for a Debrief or Point of Contact with your mentor.",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
        {
          description:
            "Complete and add a fieldwork entry for an Experience with your mentor or another attorney or judge. ",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
        {
          description:
            "Correct any returned fieldwork entries and re-submit them for approval. ",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
      ],
      extra: [
        {
          text: "Missed the deadline?",
          button: "Contact your program director.",
          path: "/help",
        },
      ],
    },
    {
      id: 8,
      header: "Year-End Fieldwork Deadline",
      link: {
        path: "/fieldworks",
        text: "View Your Fieldwork",
      },
      steps: [
        {
          description:
            "Complete and add fieldwork entries for a total of eighteen (18) hours during the academic year.",
          lvls: ["LLM", "1L"],
        },
        {
          description:
            "Complete and add fieldwork entries for a total of thirty (30) hours during the academic year.",
          lvls: ["2L", "3L"],
        },
        {
          description:
            "Complete and add fieldwork entries for a total of at least two (2) Debriefs with your mentor during the academic year.",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
        {
          description:
            "Complete and add fieldwork entries for a total of at least four (4) Distinct Experiences with your mentor or another attorney or judge during the academic year.",
          lvls: ["LLM", "1L"],
        },
        {
          description:
            "Complete and add fieldwork entries for a total of at least five (5) Distinct Experiences with your mentor or another attorney or judge during the academic year.",
          lvls: ["2L", "3L"],
        },
        {
          description:
            "Correct any returned fieldwork entries and confirm that all your entries have been approved.",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
      ],
      extra: [
        {
          text: "Need an extension?",
          button: "Contact your program director before the deadline.",
        },
        {
          text: "Missed the deadline?",
          button: "Contact your program director immediately.",
          path: "/help",
        },
      ],
    },
    {
      id: 9,
      header: "Year-End Meeting Deadline",
      link: {
        path: "/fieldworks",
        text: "Add Fieldwork Entry",
      },
      steps: [
        {
          description:
            "Follow up your mentor (if necessary) to schedule your Year-End Meeting to review your fieldwork entries.",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
        {
          description:
            "Save a PDF copy of your fieldwork entries and email a copy to your mentor prior to your meeting.",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
        {
          description:
            "Meet with your mentor to review your fieldwork entries for the year. ",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
        {
          description:
            "Add a fieldwork entry for your Year-End Meeting. Make sure to carefully review and complete the certifications you need to meet.",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
      ],
      extra: [
        {
          text: "Need an extension?",
          button: "Contact your program director before the deadline.",
        },
        {
          text: "Missed the deadline?",
          button: "Contact your program director immediately.",
          path: "/help",
        },
      ],
    },
    {
      id: 11,
      header: "Get started",
      steps: [
        {
          number: "!",
          description:
            "Select a milestone above to view helpful tips and information" +
            " for guidance on meeting the specific program milestone.",
          lvls: ["LLM", "1L", "2L", "3L"],
        },
      ],
    },
  ],
};

const getters = {
  display_step: (state) => state.homepage_milestone_display_id,
  get_milestone_step: (state) => (id, level) => {
    let step = { ...state.milestone_steps.find((s) => s.id === id) };
    step.steps = step.steps.filter((s) => s.lvls.includes(level));
    return step;
  },
  milestone_index: (state) => {
    return state.student_milestones.findIndex(
      (m) => m.due_date - Date.now() > 0
    );
  },
  is_fetching_milestones: (state) => {
    return state.fetching_milestones;
  },
};

const mutations = {
  set_milestone_step_id: (state, id) =>
    (state.homepage_milestone_display_id = id),
  set_milestone_steps_display: (state, data) => {
    state.student_milestones.forEach((milestone) => {
      if (milestone.id === data.id && milestone.display === false) {
        milestone.display = data.display;
      } else if (milestone.id === data.id && milestone.display === true) {
        // Do nothing
      } else {
        milestone.display = false;
      }
    });
  },
  set_milestone_dates_status: (state, dates) => {
    state.student_milestones.forEach((milestone) => {
      switch (milestone.id) {
        case 1:
          milestone.due_date = parseDate(dates["first_contact_due_date"]);
          break;
        case 2:
          milestone.due_date = parseDate(dates["ppdp_due_date"]);
          break;
        case 3:
          milestone.due_date = parseDate(dates["ppdp_meeting_due_date"]);
          break;
        case 4:
          milestone.due_date = parseDate(
            dates["monthly_fieldwork_oct_due_date"]
          );
          break;
        case 5:
          milestone.due_date = parseDate(dates["fieldwork_mid_year_due_date"]);
          break;
        case 6:
          milestone.due_date = parseDate(
            dates["monthly_fieldwork_jan_due_date"]
          );
          break;
        case 7:
          milestone.due_date = parseDate(
            dates["monthly_fieldwork_feb_due_date"]
          );
          break;
        case 8:
          milestone.due_date = parseDate(dates["fieldwork_year_end_due_date"]);
          break;
        case 9:
          milestone.due_date = parseDate(dates["year_end_meeting_due_date"]);
          break;
        default:
          break;
      }
      milestone.due_date.setHours(23, 59, 59);
    });
  },
  set_milestone_status(state, root_data) {
    const now = Date.now();
    const two_weeks = 1209600000;
    state.student_milestones.forEach((milestone, idx, milestones) => {
      milestone.requirement_met = checkRequirement(
        milestone,
        root_data,
        idx,
        milestones
      );
      if (milestone.due_date < now && !milestone.requirement_met) {
        milestone.status = milestone_statuses.OVERDUE;
      } else if (
        milestone.due_date > now &&
        now > parseDate(milestone.due_date).getTime() - two_weeks &&
        !milestone.requirement_met
      ) {
        milestone.status = milestone_statuses.UPCOMING;
      } else if (milestone.requirement_met) {
        //TODO logic for setting "COMPLETE"
        // check flag for progress item to observe
        // if the item complete === total, set to "COMPLETE"
        // if this is entry-related, also looks at the current date (for example, midterm)
        milestone.status = milestone_statuses.COMPLETE;
      } else {
        milestone.status = milestone_statuses.TODO;
      }
    });
  },
};

const actions = {
  fetch_milestone_dates: async ({ rootState, state, commit }) => {
    state.fetching_milestones = true;
    commit(
      "set_milestone_dates_status",
      rootState.user_selected_term.milestones
    );
    state.fetching_milestones = false;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
