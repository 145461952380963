const VueHtmlToPaper = {
  install(app, options = {}) {
    app.config.globalProperties.$htmlToPaper = (
      el,
      localOptions
    ) => {
      let defaultName = "_blank",
        defaultSpecs = ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
        defaultReplace = true,
        defaultStyles = [];
      let {
        name = defaultName,
        specs = defaultSpecs,
        replace = defaultReplace,
        // eslint-disable-next-line no-unused-vars
        styles = defaultStyles,
      } = options;
      if (localOptions) {
        if (localOptions.name) name = localOptions.name;
        if (localOptions.specs) specs = localOptions.specs;
        if (localOptions.replace) replace = localOptions.replace;
        if (localOptions.styles) styles = localOptions.styles;
      }

      specs = !specs.length ? specs.join(",") : "";

      const element = window.document.getElementById(el);

      if (!element) {
        alert(`Element to print #${el} not found!`);
        return;
      }

      const url = "";
      const win = window.open(url, name, specs, replace);
      win.document.write(`
          <html lang="en">
          <head>
          <link href="https://fonts.googleapis.com/css2?family=Lato&display=swap" rel="stylesheet">
          <script src="https://cdn.tailwindcss.com"></script>
          <title>${window.document.title}</title>
          </head>
          <style>
            table, div, label, h1, h2, h3, h4, h5, button, select, p  {
              font-size: 12px !important;
            }
          </style>
          <body class="pl-5">
            ${element.innerHTML}
          </body>
          </html>
        `);

      setTimeout(() => {
        win.document.close();
        win.focus();
        win.print();
        win.close();
      }, 1000);

      return true;
    };
  },
};

export default VueHtmlToPaper;
