<template>
  <div
    class="bg-purple text-2xl text-white absolute inset-y-0 left-0 w-48 h-full"
    :class="this.$store.state.sidebar_open ? '' : 'w-14'"
  >
    <ul class="pl-4 pt-5" id="nav">
      <li class="pb-16 flex justify-between">
        <span class="ml-0.5" v-if="this.$store.state.sidebar_open"
          >Mentor X Log</span
        >
        <div class="px-0.5">
          <button
            aria-label="toggle_sidebar"
            class="pr-4"
            @click="toggleSidebar"
          >
            <font-awesome-icon
              :icon="
                this.$store.state.sidebar_open
                  ? `chevron-circle-left`
                  : `chevron-circle-right`
              "
            />
          </button>
        </div>
      </li>

      <li v-if="isAdm()" class="nav-item" data-cy="sidebar-home">
        <router-link to="/admin">
          <font-awesome-icon icon="home" class="nav-icon" />
          <span :class="this.$store.state.sidebar_open ? '' : 'hidden'"
            >Home</span
          >
        </router-link>
      </li>

      <li class="nav-item" data-cy="sidebar-profile">
        <router-link to="/admin/students">
          <font-awesome-icon icon="user-circle" class="nav-icon" />
          <span :class="this.$store.state.sidebar_open ? '' : 'hidden'"
            >Students</span
          >
        </router-link>
      </li>

      <li
        v-if="isAdm() || isSaj() || isSra()"
        class="nav-item"
        data-cy="sidebar-plan"
      >
        <router-link to="/admin/mentors">
          <font-awesome-icon icon="calendar-alt" class="nav-icon" />
          <span :class="this.$store.state.sidebar_open ? '' : 'hidden'"
            >Mentors</span
          >
        </router-link>
      </li>

      <li v-if="isAdm() || isSra()" class="nav-item" data-cy="sidebar-mentor">
        <router-link to="/admin/pairings">
          <font-awesome-icon icon="balance-scale-left" class="nav-icon" />
          <span :class="this.$store.state.sidebar_open ? '' : 'hidden'"
            >Pairings</span
          >
        </router-link>
      </li>

      <li
        v-if="isAdm() || isSaj()"
        class="nav-item"
        data-cy="sidebar-fieldworks"
      >
        <router-link to="/admin/fieldworks">
          <font-awesome-icon icon="clipboard-list" class="nav-icon" />
          <span :class="this.$store.state.sidebar_open ? '' : 'hidden'"
            >Fieldwork</span
          >
        </router-link>
      </li>

      <li v-if="isAdm()" class="nav-item" data-cy="sidebar-contact">
        <router-link to="/admin/reports">
          <font-awesome-icon icon="comment-dots" class="nav-icon" />
          <span :class="this.$store.state.sidebar_open ? '' : 'hidden'"
            >Reports</span
          >
        </router-link>
      </li>

      <li v-if="isAdm() || isSra()" class="nav-item" data-cy="sidebar-contact">
        <router-link to="/admin/sysadmin">
          <font-awesome-icon icon="comment-dots" class="nav-icon" />
          <span :class="this.$store.state.sidebar_open ? '' : 'hidden'"
            >Sys Admin</span
          >
        </router-link>
      </li>

      <li v-if="isMasquerade() || isSra()" class="nav-item" data-cy="sidebar-masq">
        <router-link to="/">
          <font-awesome-icon icon="theater-masks" class="nav-icon" />
          <span :class="this.$store.state.sidebar_open ? '' : 'hidden'"
            >Student Home</span
          >
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import {isAdm, isSra, isFac, isSaj, isMasquerade} from "@/composables/tokenUtils";

export default {
  name: "AdminSidebar",
  methods: {
    isAdm,
    isSra,
    isFac,
    isSaj,
    isMasquerade,
    toggleSidebar() {
      this.$store.commit("toggleSidebar");
    },
  },
};
</script>

<style scoped>
.nav-item {
  @apply text-light-purple-2 hover:text-white mb-7;
}
.nav-icon {
  @apply w-5 mr-3;
}
</style>
