<template>
  <div>
    <div class="secondhead-text" data-cy="header-fieldworks">
      {{ setHeader }}
    </div>
    <div v-if="show_full_header" class="header-links">
      <router-link :to="setLink.to">
        <button class="link">
          {{ setLink.text }}
        </button>
      </router-link>
      <div
        v-if="
          this.$store.state.user_selected_term.name ===
          this.lookup_current_term.name
        "
        class="inline"
      >
        <router-link to="/fieldworks/add">
          <button class="link button">Add Entry</button>
        </router-link>
      </div>
    </div>
    <div v-if="show_full_header" :class="showDescription ? '' : 'hidden'">
      Track your progress for most recent entries.
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "MyFieldworksHeader",
  props: {
    show_full_header: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    setHeader: function () {
      return this.$route.path === "/" ? "Fieldwork Entries" : "My Entries";
    },
    showDescription: function () {
      return this.$route.path === "/";
    },
    setLink: function () {
      return this.$route.path === "/"
        ? {
            to: "/fieldworks",
            text: "View All",
          }
        : {
            to: "/fieldworks/print",
            text: "Print",
          };
    },
    ...mapGetters(["lookup_current_term"]),
  },
};
</script>

<style scoped></style>
