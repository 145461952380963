<template>
  <div
    class="bg-purple text-2xl text-white absolute inset-y-0 left-0 w-48 h-full"
    :class="this.$store.state.sidebar_open ? '' : 'w-14'"
  >
    <ul class="pl-4 pt-5" id="nav" data-cy="nav-content-sidebar">
      <li class="pb-16 flex justify-between">
        <span class="ml-0.5" v-if="this.$store.state.sidebar_open"
          >Mentor X Log</span
        >
        <div class="px-0.5">
          <button
            aria-label="toggle_sidebar"
            class="pr-4"
            @click="toggleSidebar"
          >
            <font-awesome-icon
              :icon="
                this.$store.state.sidebar_open
                  ? `chevron-circle-left`
                  : `chevron-circle-right`
              "
            />
          </button>
        </div>
      </li>

      <li class="nav-item" data-cy="sidebar-home">
        <router-link to="/" aria-label="home navigation">
          <font-awesome-icon icon="home" class="nav-icon" />
          <span :class="this.$store.state.sidebar_open ? '' : 'hidden'"
            >Home</span
          >
        </router-link>
      </li>

      <li
        v-if="!this.$store.state.summer || show_masq"
        class="nav-item"
        data-cy="sidebar-profile"
      >
        <router-link
          data-cy="student-nav-profile"
          to="/profile"
          aria-label="profile navigation"
        >
          <font-awesome-icon icon="user-circle" class="nav-icon" />
          <span :class="this.$store.state.sidebar_open ? '' : 'hidden'"
            >My Profile</span
          >
        </router-link>
      </li>

      <li
        v-if="!this.$store.state.summer || show_masq"
        class="nav-item"
        data-cy="sidebar-plan"
      >
        <router-link
          data-cy="student-nav-plan"
          to="/plan"
          aria-label="plan navigation"
        >
          <font-awesome-icon icon="calendar-alt" class="nav-icon" />
          <span :class="this.$store.state.sidebar_open ? '' : 'hidden'"
            >Plan (PPDP)</span
          >
        </router-link>
      </li>

      <li
        v-if="!this.$store.state.summer || show_masq"
        class="nav-item"
        data-cy="sidebar-mentor"
      >
        <router-link
          data-cy="student-nav-mentor"
          to="/mentor"
          aria-label="mentors navigation"
        >
          <font-awesome-icon icon="balance-scale-left" class="nav-icon" />
          <span :class="this.$store.state.sidebar_open ? '' : 'hidden'"
            >Mentor(s)</span
          >
        </router-link>
      </li>

      <li
        v-if="!this.$store.state.summer || show_masq"
        class="nav-item"
        data-cy="sidebar-fieldworks"
      >
        <router-link
          data-cy="student-nav-fieldworks"
          to="/fieldworks"
          aria-label="fieldworks navigation"
        >
          <font-awesome-icon icon="clipboard-list" class="nav-icon" />
          <span :class="this.$store.state.sidebar_open ? '' : 'hidden'"
            >Fieldwork</span
          >
        </router-link>
      </li>

      <li
        v-if="!this.$store.state.summer || show_masq"
        class="nav-item"
        data-cy="sidebar-contact"
      >
        <router-link
          data-cy="student-nav-help"
          to="/help"
          aria-label="help navigation"
        >
          <font-awesome-icon icon="comment-dots" class="nav-icon" />
          <span :class="this.$store.state.sidebar_open ? '' : 'hidden'"
            >Help</span
          >
        </router-link>
      </li>

      <li v-if="isMasquerade() || isSra()" class="nav-item" data-cy="sidebar-masq">
        <router-link to="/admin" aria-label="admin home navigation">
          <font-awesome-icon icon="theater-masks" class="nav-icon" />
          <span :class="this.$store.state.sidebar_open ? '' : 'hidden'"
            >Admin Home</span
          >
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import {isSra, isMasquerade} from "@/composables/tokenUtils";
export default {
  name: "StudentSidebar",
  emits: ["toggleSidebar"],
  data() {
    return {};
  },
  methods: {
    isSra,
    isMasquerade,
    toggleSidebar() {
      this.$store.commit("toggleSidebar");
    },
  },
};
</script>

<style scoped>
.nav-item {
  @apply text-light-purple-2 hover:text-white mb-7;
}
.nav-icon {
  @apply w-5 mr-3;
}
</style>
