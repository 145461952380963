<template>
  <div
    class="pt-3 overflow-x-auto flex overscroll-auto overflow-auto bg-white"
    :class="height"
  >
    <table
      v-if="!loading"
      class="text-dark-gray w-full table-fixed"
      data-cy="table-fieldworks"
    >
      <thead class="border-b border-cool-gray text-sm">
        <tr>
          <th class="text-left px-4 w-1/5">TYPE</th>
          <th class="text-left px-4 w-2/5">DESCRIPTION</th>
          <th class="text-left px-4">DATE OF FIELDWORK</th>
          <th class="text-left px-4">DATE SUBMITTED</th>
          <th class="text-left px-1 w-12">STATUS</th>
        </tr>
      </thead>
      <tbody class="text-sm">
        <tr
          v-for="(fieldwork, idx) in show_all
            ? table_data
            : table_data.slice(0, 5)"
          :data-cy="'table-row-fieldworks-' + idx"
          :key="idx"
          :class="
            idx === table_data.length - 1
              ? 'border-0'
              : 'border-b border-cool-gray'
          "
          class="hover:cursor-pointer hover:bg-white-lilac"
          @click="
            $router.push({
              name: 'FieldworksView',
              params: {
                id: fieldwork.fieldwork_id,
              },
            })
          "
        >
          <td class="py-1.5 px-4">
            {{ entry_type(fieldwork.fieldwork_type_id) }}
          </td>
          <td class="py-1.5 px-4">
            <div v-if="fieldwork.fieldwork_type_name === 'Experience'" class="truncate">
              {{ experience_lookup(fieldwork) }}
            </div>
          </td>
          <td class="px-4">
            {{ TableDateFormat(fieldwork.fieldwork_date) }}
          </td>
          <td class="px-4">
            {{ TableDateFormat(fieldwork.updated, true) }}
          </td>
          <td class="pl-3">
            <div class="w-3/5 mx-1">
              <div
                class="rounded-full text-center"
                :class="{
                  'bg-purple': fieldwork.event_status === 'TODO',
                  'bg-alert-warn': fieldwork.event_status === 'RETURNED',
                  'bg-alert-success': fieldwork.event_status === 'APPROVED',
                  'bg-orange': fieldwork.event_status === 'REJECTED',
                  'bg-alert-danger': fieldwork.event_status === 'DELETED',
                }"
              >
                <font-awesome-icon
                  :class="
                    fieldwork.event_status === 'RETURNED'
                      ? 'text-returned-text text-sm'
                      : 'text-white text-sm'
                  "
                  :icon="
                    fieldwork.event_status === 'APPROVED'
                      ? 'check'
                      : fieldwork.event_status === 'RETURNED'
                      ? 'reply'
                      : fieldwork.event_status === 'TODO'
                      ? 'hourglass'
                      : 'ban'
                  "
                />
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { TableDateFormat } from "@/composables/date_utils";

export default {
  name: "FieldworksTable",
  props: {
    table_data: {
      type: Array,
      required: true,
    },
    show_all: {
      type: Boolean,
      required: false,
      default: true,
    },
    height: {
      type: String,
      required: false,
      default: "h-auto",
    },
  },
  data: function () {
    return {
      loading: false,
    };
  },
  methods: {
    TableDateFormat,
    entry_type: function (type_id) {
      return (
        this.lookup_fieldwork_types.find(
          (type) => type.fieldwork_log_type_id === type_id
        ).fieldwork_type_name ?? "FIELDWORK ID NOT IN DB"
      );
    },
    experience_lookup: function (item) {
      let exp_desc = "";
      const lookup = this.lookup_fieldwork_types.find(
        (l) => l.fieldwork_log_type_id === item.fieldwork_type_id
      );
      item.fields.forEach((field) => {
        let value = lookup.fields.find(
          (l) => field.fieldwork_log_field_id === l.field_id
        );
        if (value.name === "Experience Type") {
          exp_desc = field.value;
        }
      });
      return exp_desc;
    },
  },
  computed: {
    ...mapGetters(["lookup_fieldwork_types", "student_profile"]),
  },
  async created() {
    /*let acad_level = await this.student_profile.academic_level;
    this.$store.dispatch("getFieldworkEntries", { academic_level: acad_level }).finally(
      this.loading = false
    );*/
  },
  watch: {
    loading: function (val) {
      console.log(val);
    },
  },
};
</script>

<style scoped></style>
