<template>
  <div class="relative">
    <MyFieldworksHeader :show_full_header="student_entries.length > 0" />
    <FieldworksTable
      v-if="student_entries.length > 0 && !form_loading"
      :height="height"
      :table_data="student_entries"
      :show_all="show_all"
    />
    <div v-else-if="!student_entries.length && !form_loading">
      <div class="py-5 grid grid-cols-1 md:grid-cols-3">
        <div class="col-span-1">
          <div class="w-64">
            <img :src="plan_image" alt="" />
          </div>
        </div>
        <div class="col-span-2 flex flex-col gap-3 justify-center">
          <div class="text-center text-dark-purple text-xl">
            <div>
              <strong>
                This is where your fieldwork entries will appear.</strong
              >
            </div>
            <div
              v-if="
                this.$store.state.user_selected_term.name ===
                this.lookup_current_term.name
              "
            >
              <strong>Click Add New Entry button to get started.</strong>
            </div>
          </div>
          <div
            v-if="
              this.$store.state.user_selected_term.name ===
              this.lookup_current_term.name
            "
            class="flex justify-center"
          >
            <button
              class="py-2 px-6 bg-purple hover:bg-dark-purple rounded-full text-white"
              @click="this.$router.push({ name: 'FieldworksAdd' })"
            >
              Add New Entry
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <loading-spinner />
    </div>
  </div>
</template>

<script>
import FieldworksTable from "@/components/displays/student-displays/fieldworks-logs/FieldworksTable";
import MyFieldworksHeader from "@/components/displays/student-displays/fieldworks-logs/MyFieldworksHeader";
import { mapGetters } from "vuex";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
export default {
  name: "MyFieldworks",
  props: {
    show_all: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      required: false,
      default: "h-auto",
    },
  },
  components: { LoadingSpinner, MyFieldworksHeader, FieldworksTable },
  data() {
    return {
      fieldworks: this.$store.state.DEMO_student_fieldworks,
      plan_image: require("@/../public/icons-1.3/woman-logs@2x.png"),
    };
  },
  methods: {
  },
  computed: {
    ...mapGetters(["student_entries", "form_loading", "lookup_current_term"]),
  },
};
</script>

<style scoped></style>
