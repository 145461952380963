<template>
  <div>
    <div>
      <span class="secondhead-text">Your Progress</span>
    </div>
    <ProgressBar
      v-for="(progress_item, idx) in progress_items"
      :key="idx"
      :progress_item="progress_item"
      :data-cy="`content-ProgressBar-${idx}`"
    />
  </div>
</template>

<script>
import ProgressBar from "./ProgressBar.vue";
export default {
  name: "YourProgress",
  components: {
    ProgressBar,
  },
  props: {
    progress_items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped></style>
