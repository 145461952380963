import { checkMasq } from "@/composables/tokenUtils";

const state = {
  IsMasquerading: checkMasq(),
  MasqueradeId: null,
};
const getters = {
  IsMasquerading: () => checkMasq(),
};
const mutations = {
  SetIsMasquerading: (state, payload) => {
    state.IsMasquerading = payload;
  },
};
const actions = {
  SetIsMasquerading: ({ commit }, payload) => {
    commit("SetIsMasquerading", payload);
    // console.log("SetIsMasquerading", state.IsMasquerading);
  },
  FetchMasqToken: ({ state }, payload) => {
    if (!state.IsMasquerading) return;
    window.location.href = `${process.env.VUE_APP_MASQ_URL}${payload.id}`;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
