<template>
  <div
    :aria-label="`A alert of level ${level} reading: &quot;${message}&quot;`"
    role="alertdialog"
    class="relative w-80 border border-dark-gray rounded-md shadow-lg mb-3 bg-white"
  >
    <div
      :class="{
        'bg-light-purple bg-opacity-30 text-dark-purple': level === 'info',
        'bg-alert-danger bg-opacity-20 text-error-text': level === 'error',
        'bg-alert-warn bg-opacity-30 text-': level === 'warn',
        'bg-alert-success bg-opacity-30 text-alert-success-heavy':
          level === 'success',
      }"
    >
      <div class="flex justify-start">
        <div
          :class="{
            'flex-none w-2 rounded-tl': true,
            'rounded-l': !timeout,
            'bg-dark-purple': level === 'info',
            'bg-error-text': level === 'error',
            'bg-yellow-heavy': level === 'warn',
            'bg-alert-success-heavy': level === 'success',
          }"
        ></div>
        <div class="p-3 grow">
          <div class="flex items-center justify-between">
            <div class="flex-none">
              <font-awesome-icon
                :icon="icon"
                :aria-label="`${icon} icon denoting a ${level} alert type`"
              />
            </div>
            <div class="grow mx-3 text-sm font-bold text-green-600">
              {{ message }}
            </div>
            <div class="flex-none">
              <button
                @click="$emit('remove')"
                aria-label="close alert"
                title="Click here to close alert"
              >
                <font-awesome-icon icon="times" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="timeout"
        class="rounded-bl-md h-1 w-full transition-all ease-in-out"
        :class="{
          'bg-dark-purple': level === 'info',
          'bg-error-text': level === 'error',
          'bg-yellow-heavy': level === 'warn',
          'bg-alert-success-heavy': level === 'success',
        }"
        :style="`width: ${percentage_var}%; transition-duration: ${
          maxTimeout / 4
        }ms;`"
      ></div>
    </div>
  </div>
</template>

<script>
/**
 * Toast can be used to send small alert messages to the user using a simple event defined in the compostable
 * Toasts exist at four levels: success, warn, error, and info (info being the default)
 *
 */
export default {
  name: "Toast",
  props: {
    level: {
      type: String,
      required: true,
      default: "info",
      validator: (value) => {
        return ["error", "warn", "success", "info"].includes(value);
      },
    },
    message: {
      type: String,
      required: true,
    },
    timeout: {
      type: Number,
      required: false,
    },
    maxTimeout: {
      type: Number,
      default: 10000,
    },
  },
  computed: {
    percentage_var() {
      return Math.round((this.timeout / this.maxTimeout) * 100);
    },
    icon() {
      switch (this.level) {
        case "success":
          return "check-circle";
        case "error":
          return "exclamation-circle";
        case "warn":
          return "exclamation-triangle";
        default:
          return "info-circle";
      }
    },
  },
};
</script>

<style scoped></style>
?/
