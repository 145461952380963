<template>
  <div
    id="scroll-container"
    :class="!showAdminSidebar ? 'max-w-screen-xl overflow-x-hidden' : ''"
    v-if="!loading"
  >
    <alert-toasts />
    <div v-if="this.$route.matched[0].props.default.header !== false">
      <ContentBlockSkip
        v-if="this.$route?.matched[0]?.props?.default?.sidebar !== false"
        focusId="#main-page"
      />
      <Topbar :sidebar_open="this.$store.state.sidebar_open" />
      <NewNotification
        :sidebar_open="this.$store.state.sidebar_open"
        class=""
        data-cy="content-notification-pannel"
      />
      <template
        v-if="this.$route?.matched[0]?.props?.default?.sidebar !== false"
      >
        <Sidebar />
      </template>

      <div class="flex justify-start items-center">
        <suspense>
          <router-view
            id="main-page"
            class="ml-8 h-full inset-y-0 pr-2 md:px-8 pt-8 md:pt-5 pb-24 text-base text-dark-gray w-full"
            :class="this.$store.state.sidebar_open ? 'ml-48' : 'ml-16'"
          />
        </suspense>
      </div>
    </div>

    <router-view v-else id="main-page" class="pb-24" />

    <Footer />
  </div>
  <div v-else>
    <LoadingSpinner class="pt-4" />
  </div>
</template>

<script>
import Topbar from "@/components/navigation/topbar/Topbar";
import Sidebar from "@/components/navigation/Sidebar";
import Footer from "@/components/navigation/Footer";
import { mapActions, mapGetters, mapMutations } from "vuex";
import NewNotification from "@/components/displays/alert/NewNotification";
import ContentBlockSkip from "@/components/reusable-components/ContentBlockSkip";
import {
  checkAdmin,
  getUserName,
  setToken,
  isMasquerade,
  isStu,
  checkMasq,
  isSra,
} from "@/composables/tokenUtils";
import AlertToasts from "@/components/displays/alert/AlertToasts";
import { get } from "@/composables/httpUtil";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import { handleUserPageAccess } from "@/router";
import { parseDate } from "@/composables/date_utils";

export default {
  components: {
    AlertToasts,
    Topbar,
    Sidebar,
    Footer,
    NewNotification,
    ContentBlockSkip,
    LoadingSpinner,
  },
  setup() {
    sessionStorage.setItem("APP_STATE", "LOGGING_IN");
  },
  created() {
    //load token
    this.loading = true;
    get("user")
      .then((data) => {
        //set token and declare state as logged in
        setToken(data);
        sessionStorage.setItem("APP_STATE", "LOGGED_IN");

        this.setSidebar();

        Promise.all([this.getUserData(), this.fetchLookupResource()])
          .then(() => {
            if (this.isStudent) {
              // this.isStudent check Masquerade status
              Promise.allSettled([this.fetchStudentLevels()]).then(() => {

                // Check here for summer state
                // console.log("Check to see if it's summer");

                if (checkMasq()) {
                  // Admin should ignore summer flag
                  this.$store.commit("set_summer_flag", false);
                } else if (
                  this.lookup_next_term.term_id ===
                  this.getAcadLevel.term.term_id
                ) {
                  // Is student Promoted before end of school year
                  // (student current level term ahead current term)
                  // Summer by default
                  // console.log("Student has been promoted before end of term.");
                  this.$store.commit("set_summer_flag", true);
                } else {
                  // Else current term equal or behind student current level term
                  // check dates for summer condition
                  // console.log("Term is over. Check Summer status");
                  const now = new Date();
                  if (localStorage.getItem("student_status") === "new") {
                    if (
                        parseDate(
                          this.lookup_current_term?.milestones
                            ?.new_student_site_available_date
                        )
                       > now
                    ) {
                      this.$store.commit("set_summer_flag", true);
                    }
                  } else {
                    if (
                        parseDate(
                          this.getAcadLevel.term?.milestones
                            ?.site_available_date
                        ) > now
                    ) {
                      this.$store.commit("set_summer_flag", true);
                    }
                  }
                }

                if (!this.get_summer_flag) {
                  Promise.allSettled([
                    this.fetch_milestone_dates(),
                    this.fetchStudentMentors(),
                    this.fetchPlanResource(),
                  ]).finally(() => {
                    handleUserPageAccess();
                    this.loading = false
                  });
                } else {
                  handleUserPageAccess();
                  this.loading = false;
                }
              });
            } else {
              handleUserPageAccess();
              this.loading = false;
            }
          })
          .catch((e) => {
            console.log("error (getUserData):", e);
            this.$router.push("/error");
            this.loading = false;
          });
        window.addEventListener("resize", () => {
          if (
            window.innerWidth >= 1024 &&
            this.$store.state.sidebar_open === false
          ) {
            this.$store.commit("toggleSidebar");
          }
          if (
            window.innerWidth < 1024 &&
            this.$store.state.sidebar_open === true
          ) {
            this.$store.commit("toggleSidebar");
          }
        });
      })
      .catch((error) => {
        console.log("error (get /user):", error);
        localStorage.setItem("desired_path", location.pathname);
        handleUserPageAccess();
        this.loading = false;
      });
  },
  data() {
    return {
      sidebar_open: this.$store.state.sidebar_open, // THIS IS NOTE REACTIVE!
      loading: true,
      //navigationDisplayed: false,
    };
  },
  watch: {
    getAcadLevel: {
      handler() {
        this.fetchStudentResource();
      },
    },
  },
  methods: {
    setToken,
    isMasquerade,
    get,
    handleUserPageAccess,
    getUserData() {
      return new Promise((resolve, reject) => {
        try {
          resolve(getUserName());
        } catch (e) {
          reject(e);
        }
      }).then((user) => {
        this.setUser(user);
      });
    },
    toggleMargins: function () {
      this.sidebar_open = !this.sidebar_open;
    },
    setSidebar: function () {
      this.$store.commit("set_sidebar", "student");
      if (checkAdmin()) {
        if (isMasquerade()) {
          this.$store.dispatch("SetIsMasquerading", true);
        }
        if (!location.pathname.includes("admin")) {
          this.$store.commit("set_sidebar", "student");
        } else {
          this.$store.commit("set_sidebar", "admin");
        }
      } else if (isSra() && location.pathname.includes("admin")) {
        this.$store.commit("set_sidebar", "admin");
      }
    },
    checkMasq: function () {
      return (
        this.$store.state.masquerade.IsMasquerading &&
        !this.$route.path.match("/admin/*")
      );
    },
    ...mapMutations(["setUser"]),
    ...mapActions([
      "fetch_milestone_dates",
      "fetchStudentResource",
      "fetchLookupResource",
      "fetchStudentMentors",
      "fetchStudentLevels",
      "fetchPlanResource",
    ]),
  },
  beforeUpdate() {
    this.setSidebar();
  },
  computed: {
    showAdminSidebar() {
      return this.$store.state.current_sidebar === "admin";
    },
    isStudent() {
      if (isStu()) {
        return true;
      }
      return !!checkMasq();
    },
    ...mapGetters([
      "lookup_current_term",
      "lookup_next_term",
      "getAcadLevel",
      "get_summer_flag",
    ]),
  },
};
</script>

<style>
.header-text {
  @apply text-dark-purple font-bold text-3xl;
}
.secondhead-text {
  @apply text-dark-purple font-bold text-2xl mb-1.5;
}
.thirdhead-text {
  @apply text-dark-purple font-bold text-xl;
}
.fourthhead-text {
  @apply text-dark-purple font-bold text-base;
}
.subhead-text {
  @apply text-xl mb-4;
}
.req {
  @apply font-normal text-required;
}
.box-style {
  @apply shadow-sm bg-white rounded-md p-5;
}
.anchor {
  @apply text-violet;
}
.header-links {
  @apply absolute inset-y-0 right-0 top-0.5 text-violet;
}
.header-links .link {
  @apply inline-block ml-2 font-bold hover:underline hover:text-dark-purple;
}
.header-links .button {
  @apply bg-purple text-white rounded-3xl px-3 py-1 hover:bg-dark-purple hover:text-white hover:no-underline;
}
.router-link-active {
  @apply text-white underline decoration-2 underline-offset-8;
}
.profile_box {
  @apply border-t border-cool-gray pt-4 mt-5;
}
</style>
