<template>
  <div class="flex flex-col">
    <div class="relative">
      <span class="secondhead-text">{{ milestone_steps.header }}</span>
    </div>
    <div class="step-wrapper" data-cy="content-home-Milestone-steps">
      <div v-for="(step, key) in milestone_steps.steps" :key="key" class="step">
        <div class="my-0.5">
          <div class="flex pl-3.5 pt-2 bg-white relative">
            <div
              class="
                self-center
                border border-dark-purple
                bg-purple
                rounded-full
                text-sm text-white
                pt-0.5
                text-center
                h-6
              "
            >
              <div :class="{ 'w-6': true, 'step-count': step.number !== '!' }">
                <font-awesome-icon icon="check" v-if="step.number === '!'" />
              </div>
            </div>
            <div class="self-center pl-3">
              <div class="">{{ step.name }}</div>
              <div class="header" :class="step.number === '!' ? 'text-xl' : ''">
                {{ step.description }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="" v-if="milestone_steps.link">
      <div class="border border-cool-gray my-2 mb-4"></div>
      <button
        class="
          bottom-0
          bg-purple
          text-white
          rounded-3xl
          px-3
          py-1
          hover:bg-dark-purple hover:text-white hover:no-underline
        "
        @click="$router.push(milestone_steps.link.path)"
      >
        {{ milestone_steps.link.text }}
      </button>
    </div>
    <div class="" v-if="milestone_steps.extra">
      <div v-for="extra in milestone_steps.extra" :key="extra" class="my-2">
        <div class="">{{ extra.text }}</div>
        <div
          class="
            text-violet
            inline-block
            font-bold
            hover:underline hover:text-dark-purple hover:cursor-pointer
          "
          @click="$router.push(extra.path)"
        >
          {{ extra.button }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MyPlan",
  props: {
    display_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      milestone_steps: [],
    };
  },
  watch: {
    display_id(new_id) {
      this.gets_steps(new_id, Object.keys(this.getAcadLevel).includes('acad_level') ? this.getAcadLevel.acad_level.level : this.getAcadLevel.level);
    },
    getAcadLevel: {
      handler(newAcadLevel) {
        this.gets_steps(this.display_id, newAcadLevel.level);
      },
    },
  },
  created() {
    this.gets_steps(this.display_id, this.getAcadLevel?.acad_level?.level);
  },
  methods: {
    gets_steps(id, lvl) {
      this.milestone_steps = this.get_milestone_step(id, lvl);
    },
  },
  computed: {
    ...mapGetters(["get_milestone_step", "getAcadLevel"]),
  },
};
</script>

<style scoped>
.step-wrapper {
  counter-reset: classCounter;
}

.step-wrapper .step-count::before {
  counter-increment: classCounter;
  content: counter(classCounter);
}
</style>
