<template>
  <div class="flex justify-end self-center order-last">
    <!--    <AlertBellIcon :source="DEMO_source" />-->
    <div class="relative ml-3 align-middle inline-block group">
      <!--      <img-->
      <!--        class="h-8 w-8 rounded-full"-->
      <!--        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"-->
      <!--        alt=""-->
      <!--      />-->
      <!--      <div class="absolute flex justify-end">-->
      <!--        <div-->
      <!--          :class="[-->
      <!--            show_dropdown ? '' : 'invisible',-->
      <!--            'border rounded bg-white-lilac shadow-sm text-center px-12 underline text-dark-purple mt-1',-->
      <!--          ]"-->
      <!--        >-->
      <Button text="Logout" size="small" @click="logout()" />
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <!--    <div class="ml-3 inline-block text-xl text-indigo self-center items-center">-->
    <!--      <span class="hidden lg:inline" v-if="fullname"> {{ fullName }}</span>-->
    <!--      <button-->
    <!--        class="px-1"-->
    <!--        @click="show_dropdown = !show_dropdown"-->
    <!--        aria-label="top right user naviation menu"-->
    <!--      >-->
    <!--        <font-awesome-icon class="mt-1" icon="chevron-down" />-->
    <!--      </button>-->
    <!--    </div>-->
  </div>
</template>

<script>
// import AlertBellIcon from "@/components/displays/alert/AlertBellIcon";
import Button from "@/components/reusable-components/base-level-components/Button";

import { unsetToken } from "@/composables/tokenUtils";
import { mapGetters } from "vuex";
import {post} from "@/composables/httpUtil";
export default {
  name: "TopbarDropdown",
  components: {
    // AlertBellIcon,
    Button,
  },
  data() {
    return {
      show_dropdown: false,
      profile: null,
    };
  },
  computed: {
    ...mapGetters(["fullName"]),
  },
  methods: {
    post,
    logout() {
      this.post("logout").then(() => {
        unsetToken();
        this.$router.push("/logout");
      });
    },
  },
};
</script>

<style scoped></style>
