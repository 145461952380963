<template>
  <div class="flex pl-1 pt-3">
    <div class="shrink-0 h-12 w-12 rounded-full block">
      <img class="rounded-full" :src="director.img_src" :alt="director.name" />
    </div>
    <div class="pl-1">
      <div class="font-bold text-base">{{ director.name }}</div>
      <div class="pb-1 text-xs">{{ director.title }}</div>
      <div>
        <div class="text-purple-link text-sm">{{ director.phone }}</div>
        <div class="text-purple-link text-sm">
          <a :href="`mailto:${director.email}`">{{ director.email }}</a>
        </div>
        <div class="text-xs">{{ director.location }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgramDirector",
  props: {
    director: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
