import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/student/Home.vue";
import {
  checkJwtGroups,
  getJwtStatus,
  isStu,
  isSra,
  isMasquerade,
} from "@/composables/tokenUtils";
import getters from "@/store/index.js";

const routes = [
  {
    path: "/",
    name: "Home",
    props: { title: "Home" },
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    props: { title: "Login" },
    component: () => import("../views/shared/Login.vue"),
  },
  {
    path: "/logout",
    name: "Logout",
    props: { title: "Logout" },
    component: () => import("../views/shared/Logout.vue"),
  },
  {
    path: "/access",
    name: "PermissionDenied",
    component: () => import("../views/shared/errors/PermissionDenied.vue"),
  },
  {
    path: "/expired",
    name: "LinkExpired",
    component: () => import("../views/shared/errors/LinkExpired.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    props: { title: "Profile" },
    component: () => import("../views/student/profile/Profile.vue"),
  },
  {
    path: "/profile/edit",
    name: "ProfileEdit",
    props: { title: "Edit Profile" },
    component: () => import("../views/student/profile/ProfileEdit.vue"),
  },
  {
    path: "/mentor",
    name: "Mentor",
    props: { title: "Mentor" },
    component: () => import("../views/student/mentor/Mentor.vue"),
  },
  // {
  //   path: "/mentor/eval",
  //   name: "MentorEval",
  //   props: { title: "Mentor Evaluation" },
  //   component: () => import("../views/student/mentor/MentorEval.vue"),
  // },
  {
    path: "/summer/mentor/eval/:mentor_id",
    name: "MentorEval",
    props: (route) => ({
      title: "Mentor Evaluation",
      id: route.params.mentor_id,
      topbarAcademicFilter: false,
    }),
    component: () => import("../views/student/mentor/MentorEval.vue"),
  },
  {
    path: "/mentor/eval/success",
    name: "MentorEvalSuccess",
    props: { title: "Success" },
    component: () => import("../views/student/mentor/MentorEvalSuccess.vue"),
  },
  {
    path: "/plan",
    name: "Plan",
    props: { title: "Plan" },
    component: () => import("../views/student/plan/Plan.vue"),
  },
  {
    path: "/plan/edit/:mentor_id",
    name: "PlanEdit",
    props: (route) => ({ title: "Plan Edit", id: route.params.mentor_id }),
    component: () => import("../views/student/plan/PlanEdit.vue"),
  },
  {
    path: "/plan/view/:mentor_id",
    name: "PlanView",
    props: (route) => ({ title: "Plan View", id: route.params.mentor_id }),
    component: () => import("../views/student/plan/PlanView.vue"),
  },
  {
    path: "/plan/success",
    name: "PlanSuccess",
    props: { title: "Plan Success" },
    component: () => import("../views/student/plan/PlanSuccess.vue"),
  },
  {
    path: "/fieldworks",
    name: "Fieldworks",
    props: { title: "Fieldwork" },
    component: () => import("../views/student/fieldwork/Fieldworks.vue"),
    children: [],
  },
  {
    path: "/fieldworks/print",
    name: "FieldworksPrint",
    props: { title: "FieldworksPrint" },
    component: () => import("../views/student/fieldwork/FieldworksPrint.vue"),
    children: [],
  },
  {
    path: "/fieldworks/add",
    name: "FieldworksAdd",
    props: { title: "Add Fieldwork" },
    component: () => import("../views/student/fieldwork/FieldworksAdd.vue"),
  },
  {
    path: "/fieldworks/add/success",
    name: "FieldworksSuccess",
    component: () => import("../views/student/fieldwork/FieldworksSuccess.vue"),
    props: (route) => ({
      entry_type: route.query.entry_type,
      isUpdate: route.query.isUpdate,
    }),
  },
  {
    path: "/fieldworks/add/:type",
    name: "GenerateForm",
    props: (route) => ({
      type: route.params.type,
      title: `Add ${route.params.type}`,
    }),
    component: () => import("../views/student/fieldwork/form/GenerateForm.vue"),
  },
  {
    path: "/fieldworks/add/ppdp",
    name: "AddPlan",
    props: { title: "Add PPDP" },
    component: () => import("../views/student/fieldwork/plan/AddPlan.vue"),
  },
  {
    path: "/fieldworks/edit/:id",
    name: "FieldworksEdit",
    props: (route) => ({ title: "Edit Fieldwork", id: route.params.id }),
    component: () => import("../views/student/fieldwork/FieldworksEdit.vue"),
  },
  {
    path: "/fieldworks/:id",
    name: "FieldworksView",
    props: (route) => ({
      id: route.params.id,
      title: "View Fieldwork",
      fromRoute: route.params.fromRoute,
    }),
    component: () => import("../views/student/fieldwork/FieldworksView.vue"),
  },
  {
    path: "/help",
    name: "Help",
    props: { title: "Help", topbarAcademicFilter: false },
    component: () => import("../views/student/help/Help.vue"),
  },
  {
    path: "/admin",
    name: "AdminHome",
    props: { title: "Admin Home", topbarAcademicFilter: false },
    component: () => import("../views/admin/home/AdminHome.vue"),
  },
  {
    path: "/admin/fieldworks",
    name: "AdminFieldworks",
    props: { title: "Admin Fieldwork", topbarAcademicFilter: false },
    component: () => import("../views/admin/fieldworks/FieldworksRecords.vue"),
  },
  {
    path: "/admin/fieldworks/:id",
    name: "AdminFieldworksDetail",
    props: { title: "Admin Fieldwork", topbarAcademicFilter: false },
    component: () => import("../views/admin/fieldworks/FieldworksView.vue"),
  },
  {
    path: "/admin/mentors",
    name: "AdminMentors",
    props: { title: "Admin Mentor", topbarAcademicFilter: false },
    component: () => import("../views/admin/mentors/MentorsRecords.vue"),
  },
  {
    path: "/admin/mentors/add",
    name: "AddMentors",
    props: { title: "Mentor Add", topbarAcademicFilter: false },
    component: () => import("../views/admin/mentors/AddEditMentor.vue"),
  },
  {
    path: "/admin/mentors/edit/:id",
    name: "EditMentors",
    props: { title: "Edit Mentor", topbarAcademicFilter: false },
    component: () => import("../views/admin/mentors/AddEditMentor.vue"),
  },
  {
    path: "/admin/mentors/view/:id",
    name: "ViewMentors",
    props: { title: "Mentor", topbarAcademicFilter: false },
    component: () => import("../views/admin/mentors/MentorDetail.vue"),
  },
  {
    path: "/admin/mentors/detail",
    name: "AdminMentorDetail",
    props: { title: "Mentor Detail", topbarAcademicFilter: false },
    component: () => import("../views/admin/mentors/MentorDetail.vue"),
  },
  {
    path: "/admin/students",
    name: "AdminStudents",
    props: { title: "Admin Students", topbarAcademicFilter: false },
    component: () => import("../views/admin/students/StudentsRecords.vue"),
  },
  {
    path: "/admin/students/detail/:id",
    name: "AdminStudentsDetail",
    props: { title: "Student Detail", topbarAcademicFilter: false },
    component: () => import("../views/admin/students/StudentDetail.vue"),
  },
  {
    path: "/admin/pairings",
    name: "AdminPairings",
    props: { title: "Pairing", topbarAcademicFilter: false },
    component: () => import("../views/admin/pairing/PairingManagement.vue"),
  },
  {
    path: "/admin/reports",
    name: "AdminReports",
    props: { title: "Reports", topbarAcademicFilter: false },
    component: () => import("../views/admin/reports/Reports.vue"),
  },
  {
    path: "/admin/reports/student_export",
    name: "AdminReportsStudentExport",
    props: { title: "Student Export", topbarAcademicFilter: false },
    component: () => import("../views/admin/reports/StudentExport.vue"),
  },
  {
    path: "/admin/reports/interest_pairing_factors",
    name: "AdminReportsInterestPairingFactors",
    props: {
      title: "Interest Areas / Pairing Factors",
      topbarAcademicFilter: false,
    },
    component: () =>
      import("../views/admin/reports/InterestPairingFactors.vue"),
  },
  {
    path: "/admin/reports/milestones",
    name: "AdminReportsMilesstones",
    props: { title: "Milestone Report", topbarAcademicFilter: false },
    component: () => import("../views/admin/reports/Milestones.vue"),
  },
  {
    path: "/admin/reports/mentors_status",
    name: "AdminReportsMentorsByStatus",
    props: { title: "Mentors by Status", topbarAcademicFilter: false },
    component: () => import("../views/admin/reports/MentorsByStatus.vue"),
  },
  {
    path: "/admin/reports/retired_mentors",
    name: "AdminReportsRetiredMentors",
    props: { title: "Retired Mentors", topbarAcademicFilter: false },
    component: () => import("../views/admin/reports/RetiredMentors.vue"),
  },
  {
    path: "/admin/reports/mentor_scores",
    name: "AdminReportsMentorScores",
    props: { title: "Mentor Survey Scores", topbarAcademicFilter: false },
    component: () => import("../views/admin/reports/MentorScores.vue"),
  },
  {
    path: "/admin/reports/survey_results",
    name: "AdminReportsSurveyResults",
    props: { title: "Survey Results", topbarAcademicFilter: false },
    component: () => import("../views/admin/reports/SurveyResults.vue"),
  },
  {
    path: "/admin/reports/usage_report",
    name: "AdminReportsUsageReport",
    props: { title: "Usage Report", topbarAcademicFilter: false },
    component: () => import("../views/admin/reports/UsageReport.vue"),
  },
  {
    path: "/admin/reports/usage_descriptions",
    name: "AdminReportsUsageDescriptions",
    props: { title: "Usage Descriptions", topbarAcademicFilter: false },
    component: () => import("../views/admin/reports/UsageDescriptions.vue"),
  },
  {
    path: "/admin/reports/pairings",
    name: "AdminReportsPairings",
    props: { title: "Student/Mentor Pairings", topbarAcademicFilter: false },
    component: () => import("../views/admin/reports/Pairings.vue"),
  },
  {
    path: "/admin/reports/auto_pairing",
    name: "AdminReportsAutoPairing",
    props: { title: "Auto Pairing", topbarAcademicFilter: false },
    component: () => import("../views/admin/reports/AutoPairing.vue"),
  },
  {
    path: "/admin/sysadmin",
    name: "SysAdmin",
    props: { title: "System Admin", topbarAcademicFilter: false },
    component: () => import("../views/admin/sys-administration/SysAdmin.vue"),
  },
  {
    path: "/admin/sysadmin/experiences",
    name: "AdminExperiences",
    props: { title: "Admin Experiences", topbarAcademicFilter: false },
    component: () =>
      import("../views/admin/sys-administration/experiences/Experiences.vue"),
  },
  {
    path: "/admin/sysadmin/debrief-templates",
    name: "AdminDebriefTemplates",
    props: { title: "Debrief", topbarAcademicFilter: false },
    component: () =>
      import(
        "../views/admin/sys-administration/debrief-templates/DebriefTemplates.vue"
      ),
  },
  {
    path: "/admin/sysadmin/practice-areas",
    name: "AdminPracticeAreas",
    props: { title: "Practice Areas", topbarAcademicFilter: false },
    component: () =>
      import(
        "../views/admin/sys-administration/practice_areas/PracticeAreas.vue"
      ),
  },
  {
    path: "/admin/sysadmin/student-promotion",
    name: "AdminStudentPromotion",
    props: { title: "Student Promotion", topbarAcademicFilter: false },
    component: () =>
      import(
        "../views/admin/sys-administration/student-promotion/StudentPromotion.vue"
      ),
  },
  {
    path: "/admin/sysadmin/employers",
    name: "AdminEmployers",
    props: { title: "Employers", topbarAcademicFilter: false },
    component: () =>
      import("../views/admin/sys-administration/employers/Employers.vue"),
  },
  {
    path: "/admin/sysadmin/employers/view/:id",
    name: "AdminEmployerView",
    props: { title: "View Employer", topbarAcademicFilter: false },
    component: () =>
      import("../views/admin/sys-administration/employers/ViewEmployer.vue"),
  },
  {
    path: "/admin/sysadmin/employers/add",
    name: "AdminAddEmployer",
    props: { title: "Add Employer", topbarAcademicFilter: false },
    component: () =>
      import("../views/admin/sys-administration/employers/AddEditEmployer.vue"),
  },
  {
    path: "/admin/sysadmin/employers/edit/:id/",
    name: "AdminEditEmployer",
    props: { title: "Edit Employer", topbarAcademicFilter: false },
    component: () =>
      import("../views/admin/sys-administration/employers/AddEditEmployer.vue"),
  },
  {
    path: "/admin/sysadmin/student-surveys",
    name: "AdminStudentSurveys",
    props: { title: "Surveys", topbarAcademicFilter: false },
    component: () =>
      import("../views/admin/sys-administration/surveys/StudentSurveys.vue"),
  },
  {
    path: "/admin/sysadmin/mentor-surveys",
    name: "AdminMentorSurveys",
    props: { title: "Mentor Surveys", topbarAcademicFilter: false },
    component: () =>
      import("../views/admin/sys-administration/surveys/MentorSurvey.vue"),
  },
  {
    path: "/admin/sysadmin/users",
    name: "AdminUsers",
    props: { title: "Admin Users", topbarAcademicFilter: false },
    component: () =>
      import("../views/admin/sys-administration/settings/Users.vue"),
  },
  {
    path: "/admin/sysadmin/banner",
    name: "AdminBanner",
    props: { title: "Admin Banner", topbarAcademicFilter: false },
    component: () =>
      import("../views/admin/sys-administration/settings/Banner.vue"),
  },
  {
    path: "/admin/sysadmin/milestones",
    name: "AdminMilestones",
    props: { title: "Milestones", topbarAcademicFilter: false },
    component: () =>
      import("../views/admin/sys-administration/settings/Milestones.vue"),
  },
  {
    path: "/mentor/survey",
    name: "MentorSurvey",
    props: { title: "Mentor Survey", header: false, sidebar: false },
    component: () => import("../views/surveys/MentorSurvey.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    props: { title: "Not Found", topbarAcademicFilter: false },
    component: () => import("../views/shared/errors/NotFound.vue"),
  },
  {
    path: "/summer/profile/edit",
    name: "ProfileEditSummer",
    props: {
      title: "Edit Profile",
      header: true,
      topbarAcademicFilter: false,
    },
    component: () => import("../views/student/profile/ProfileEdit.vue"),
  },
  {
    path: "/summer",
    name: "SummerHome",
    props: {
      title: "Home",
      header: true,
      topbarAcademicFilter: false,
    },
    component: () => import("../views/student/summer/SummerHome.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  /*
    https://github.com/vuejs/vue-router/issues/3451 for documentation on this method
  */
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      window.scrollTo(0, 0);
    }
  },
});

router.beforeResolve((to) => {
  const title = "MentorXLog";
  if (to.matched[0]?.props?.default?.title !== undefined) {
    document.title = to.matched[0]?.props?.default?.title + " | " + title;
  } else {
    document.title = title;
  }

  if (sessionStorage.getItem("APP_STATE") === "LOGGED_IN") {
    //if user is logged in, check to see if they have access to view the page they are attempting to route to
    userPageAccessValidation(to.path);
  }
});

function userPageAccessValidation(path) {
  const staticPublicPages = ["/access", "/error", "/expired", "/logout"];
  let authRequired = !staticPublicPages.includes(path);

  //checks to see if there is a token that's valid
  if (authRequired) {
    if (getJwtStatus() === false) {
      // console.log("Token Invalid. Stashing: " + path);
      localStorage.setItem("desired_path", path);
      if (!process.env.VUE_APP_LOCAL) {
        window.location.href = process.env.VUE_APP_SSO_REDIRECT;
      }
    }

    //checks to see that user has access to the route they are attemping to go to
    if (checkJwtGroups(path) === false) {
      router.push("/access");
    }

    //checks to see if site is open at this time for the student to route to the url they are going too
    handleAccess(path);
  }
}

export function handleUserPageAccess() {
  //used to validate a user has access to the current page
  //this function will route the user if they do not have access
  userPageAccessValidation(location.pathname);
}

export function handleAccess(path) {
  // console.log("handleAccess", path)
  const lookups = getLookups();
  if (lookups && (isStu() || isMasquerade())) {
    if ((isSra() || isMasquerade()) && path.includes("admin")) {
      // console.log("Going to an admin page")
      return;
    }
    //if they are a student and only have student permissions
    if (getters.getters.get_summer_flag === true && !path.includes("/summer")) {
      router.push("/summer");
    } else if (
      getters.getters.get_summer_flag === false &&
      path.includes("/summer")
    ) {
      router.push("/");
    }
  } else {
    if (path.match("^/$") && !isMasquerade()) {
      router.push("/admin");
    }
  }
}

function getLookups() {
  return JSON.parse(localStorage.getItem("lookups"));
}

export default router;
