<template>
  <AdminSidebar
    v-if="(checkAdmin() || isSra) && this.$route.path.match('/admin/*')"
    :sidebar_open="this.$store.state.sidebar_open"
  />
  <StudentSidebar v-else :sidebar_open="this.$store.state.sidebar_open" />
</template>

<script>
import StudentSidebar from "@/components/navigation/StudentSidebar";
import AdminSidebar from "@/components/navigation/AdminSidebar";
import { checkAdmin, isSra } from "@/composables/tokenUtils";
export default {
  name: "Sidebar",
  components: {
    StudentSidebar,
    AdminSidebar,
  },
  methods: {
    checkAdmin,
    isSra,
  },
};
</script>

<style scoped></style>
