<template>
  <div class="pt-6 inset-y-0 top-0.5 font-bold">
    <div class="flex justify-between items-center">
      <div>{{ progress_item.name }}</div>
      <div class="">
        <span v-if="!show_circle_check" :data-cy="`${cy_data_name}`">
          <span :class="progress_complete_class">{{
            progress_item.progress.complete === 0
              ? "Not Started"
              : progress_item.progress.complete
          }}</span
          >/ {{ progress_item.progress.total }}
        </span>
        <span v-if="show_circle_check">
          <font-awesome-icon class="text-green text-3xl" icon="check-circle" />
        </span>
        <span v-if="progress_item.name === 'Fieldwork'" class="px-1">hrs</span>
      </div>
    </div>
    <div class="pt-1.5">
      <div class="mb-0.5 bg-cool-gray rounded-xl h-3 w-full"></div>
      <div
        class="-mt-3.5 bg-atlantis rounded-xl h-3 w-full"
        :style="`width: ${percentage_var}%`"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    progress_item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    percentage_var: function () {
      return Math.round(
        (this.progress_item.progress.complete /
          this.progress_item.progress.total) *
          100
      );
    },
    progress_complete_class: function () {
      return this.progress_item.progress.complete === 0 ? `px-1` : `px-1`;
    },
    show_circle_check: function () {
      return (
        this.progress_item.progress.complete >=
        this.progress_item.progress.total
      );
    },
    cy_data_name: function () {
      return `progress-item-${this.progress_item.name.replace(/\s/g, "")}`;
    },
  },
};
</script>

<style scoped></style>
