<template>
<!--THIS COMPONENT IS NOT BEING USED RIGHT NOW-->
  <div class="flex justify-start w-full" :class="$route.path !== '/' ? 'hidden' : ''">
    <div class="w-full" :class="[showNotification(), sidebar_open ? 'ml-48' : 'ml-16']">
      <div class="relative bg-light-gray border border-grey rounded p-2">
        <div class="text-lg font-bold">{{ content.title }}</div>
        <div class="text-base">
          {{ content.message }}
        </div>
        <div class="text-sm">
          {{ date }}
          &bullet; {{ time }} {{ content.DEMO_time }}
        </div>
        <button
          class="absolute top-0 right-0 px-3 text-3xl text-steel-gray"
          @click="toggleNotification()"
        >
          &times;
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewNotification",
  props: {
    sidebar_open: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const current_datetime = new Date();
    return {
      current_datetime,
    };
  },
  data() {
    return {
      time: this.current_datetime.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      }),
      date: this.current_datetime.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      }),
      content: this.$store.state.notification_content,
    };
  },
  methods: {
    showNotification() {
      return this.$store.state.notification && this.$store.state.loggedIn
        ? "px-2 md:px-8 pt-4"
        : "px-8 pt-4 hidden";
    },
    toggleNotification() {
      this.$store.commit("toggle_notification", false);
    },
  },
};
</script>

<style scoped></style>
