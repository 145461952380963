<template>
  <div class="flex items-center justify-center">
    <font-awesome-icon
      icon="spinner"
      spin
      aria-label="application is loading"
    />
    <p ref="loading" class="ml-2">LOADING</p>
  </div>
</template>

<script>
//-------------------------------------------------------------------------------------------------------
// Purpose: provide a consistant loading message to be shown to the user when an API call is being made
//-------------------------------------------------------------------------------------------------------

export default {
  name: "LoadingSpinner",
  mounted() {
    this.$refs.loading.focus(); 
  }
};
</script>
