// state
/*eslint-disable*/
const state = {
    student_id: "",
    ust_id: "",
    last_active_date: "",
    prefix: "",
    firstname: "",
    lastname: "",
    suffix: "",
    address: [],
    email: [],
    phone: [],
    added: "",
    updated: "",
    deleted: null,
    student_admit_term: {},
    student_completion_term: {},
    student_status: "",
    profiles: [],
    mentors: [],
    student_loading: true,
    mentors_loading: false,
    nickname: "",
    gender: "",
    pronons: "",
    education: [],
    ethnicity: [],
};

// getters
const getters = {
    student_id: (state) => state.student_id,
    student_name: (state) => `${state.firstname} ${state.lastname}`,
    primaryAddress: (state) => state.address.find(address => address.is_primary && address.address_type !== "STUDENT_LOCAL"),
    primaryEmail: (state) => state.email.find(email => email.is_primary),
    primaryPhone: (state) => {
        const phone = state.phone.find(number => number.is_primary)
        if (phone) {
            if (typeof phone.phone_number !== "object") {
                const phone_number = phone.phone_number.split('-')
                phone.phone_number = {
                    area_code: phone_number[0],
                    prefix: phone_number[1],
                    line: phone_number[2]
                }
            }
            return phone;
        } else {
            return false;
        }
    },
    localAddress: (state) => state.address.find(address => address.address_type === 'STUDENT_LOCAL'),
    localEmail: (state) => state.email.find(email => email.email_type === 'STUDENT_LOCAL'),
    localPhone: (state) => {
        const phone = state.phone.find(number => number.phone_type === 'STUDENT_LOCAL')
        if (phone) {
            if (typeof phone.phone_number !== "object") {
                const phone_number = phone.phone_number.split('-')
                phone.phone_number = {
                    area_code: phone_number[0],
                    prefix: phone_number[1],
                    line: phone_number[2]
                }
            }
            return phone;
        } else {
            return false;
        }
    },
    student_profile: (state, rootState) => {
        return state.profiles.length === 1 ? state.profiles[0] : state.profiles.find(profile => profile.acad_level.level === rootState.acad_level)
    },
    student_mentors: (state) => {
        return state.mentors;
    },
}

// mutators
const mutations = {
    update_student(state, student) {
        Object.entries(student).forEach(([key, value]) => {
            state[key] = value ? value : '';
        });
    },

    update_mentors(state, mentors) {
        state.mentors = mentors;
    }
}

// actions
const actions = {
    fetchStudentResource: ({commit, dispatch, state ,rootState}) => {
        state.student_loading = true;
        return dispatch('fetchResource', {
                method: "GET",
                resource: "student",
                params: {student_at_level: rootState.level_store.acad_level.student_at_level_id}
            }
        )
            .then(data => {
                if(data.student_status !== "ACTIVE") {
                    localStorage.setItem("student_status", "graduated")
                }
                commit('update_student', data)
            })
            .finally(() => state.student_loading = false)
    },
    fetchStudentMentors: async ({state, commit, dispatch, rootState}) => {
        state.mentors_loading = true;
        return await dispatch('fetchResource', {
                method: "GET",
                resource: "student/mentors",
            params: {student_at_level: rootState.level_store.acad_level.student_at_level_id}
            }
        )
            .then(data => commit('update_mentors', data))
            .finally(() => state.mentors_loading = false)
    },
    putStudentResource: async ({commit, dispatch, state, rootState}, student) => {
        state.student_loading = true;
        return await dispatch('fetchResource', {
            method: "PUT",
            resource: "student",
            params: {student_at_level: rootState.level_store.acad_level.student_at_level_id},
            body: student
        })
            .then(data => commit('update_student', data))
            .finally(() => state.student_loading = false)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
