<template>
    <a class="skip-main w-0 h-0 h-auto absolute" v-bind:href="focusId" >{{displayMessage}}</a>
</template>

<script>
//-------------------------------------------------------------------------------------------------------
// Purpose: for 508 compliancy all repeated content should be skippable. This button will only display when it is focused on by using tab commands. This will then display and allow the user to bypass any repeated content
// Inputs:
//      focusId - the value should be passed to point to the id of the html element you wish to focus too
//      displayMessage - the value here should be passed to set the text displayed on the button
//-------------------------------------------------------------------------------------------------------

export default {
  name: "ContentBlockSkip",
  components: {},
  props: {
    focusId: {
        type: String
    },
    displayMessage: {
        type: String,
        default: 'Skip to main content'
    }
  },
  methods: {
  },
};
</script>

<style scoped>
a.skip-main:focus {
  @apply bg-purple text-white w-full my-5 rounded-md p-2 text-center z-10;
}
</style>