<template>
  <div class="absolute left-2/3 top-12 z-[110]">
    <div v-for="(alert, id) in alerts" :key="id">
      <toast
        :level="alert.level"
        :message="alert.message"
        :timeout="alert.timeout"
        :max-timeout="alert.details.timeout"
        @remove="popAlert(alert.id)"
      />
    </div>
  </div>
</template>

<script>
import Toast from "@/components/displays/alert/Toast";
export default {
  name: "AlertToasts",
  components: { Toast },
  data() {
    return {
      alerts: [],
    };
  },
  mounted() {
    window.addEventListener("alertEvent", this.onAlert);
  },
  unmounted() {
    window.removeEventListener("alertEvent", this.onAlert);
  },
  methods: {
    onAlert(alert) {
      alert = alert.detail;
      alert.timeout = alert.details.timeout;

      this.alerts.push(alert);

      let i = undefined;

      function timeout() {
        alert.timeout = alert.timeout - alert.details.timeout / 4;
        if (alert.timeout <= 0) {
          clearInterval(i);
        }
      }

      if (alert.timeout) {
        i = setInterval(timeout, alert.details.timeout / 4);

        setTimeout(() => this.popAlert(alert.id), alert.details.timeout);
      }
    },
    popAlert(alertId) {
      const id = this.alerts.findIndex((alert) => alert.id === alertId);
      this.alerts.splice(id, 1);
    },
  },
};
</script>
