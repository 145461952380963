<template>
  <div
    class="absolute inset-x-0 bottom-0 h-14 bg-dark-purple text-base text-light-gray"
  >
    <div class="footer-content left-5">
      <a class="block" href="https://stthomas.edu/"
        >&copy; University of St. Thomas, Minnesota</a
      >
    </div>
    <!--TODO: These will only be used if Judie & Uyen let us know there are
         policy/legal links that need to be included from their legal department
        <div class="footer-content right-5">
      <a class="footer-link" href="#">Link1</a>
      <a class="footer-link" href="#">Link2</a>
      <a class="footer-link" href="#">Link3</a>
    </div>-->
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.footer-link {
  @apply inline-block ml-2 px-3;
}
.footer-content {
  @apply absolute inset-y-0 my-4 pt-0.5;
}
</style>
