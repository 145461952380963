import { createStore } from "vuex";
import router from "@/router";
import student_state from "./student_store";
import lookup_data_store from "./lookup_data_store";
import fieldwork_form_store from "./fieldwork_form_store";
import plan_store from "@/store/plan_store";
import milestone_store from "@/store/milestone_store";
import { fetch_resource } from "@/composables/fetch_resource";
import masquerade_store from "@/store/masquerade_store";
import user_store from "@/store/user_store";
import level_store from "@/store/level_store";

export default createStore({
  state: {
    sidebar_open: true,
    fieldwork_index: 0,
    alert: true,
    notification: true,
    notification_content: {
      title: "Welcome to Mentor Log!",
      message: `Here you can find your mentor information, keep up to date on upcoming milestones, track your progress towards requirements, log your required fieldwork, and more!`,
    },
    // current_term: null,
    user_selected_term: null,
    desired_path: "",
    groups: [],
    current_sidebar: "student",
    students: [],
    mentors: [],
    mentor: {},
    fieldworks: [],
    fieldwork: {},
    employers: [],
    employer: {},
    summer: false,
    summer_mentors: [],
    program_directors: [
      {
        name: "Uyen Campbell",
        title: "PROGRAM DIRECTOR",
        phone: "(651) 962-4959",
        email: "ucampbell@stthomas.edu",
        location: "MSL 330",
        img_src: require("../../public/icons-1.3/headshot-UyenCampbell.jpg"),
      },
      // {
      //   name: "Judie Rush",
      //   title: "PROGRAM DIRECTOR",
      //   phone: "(651) 962-4898",
      //   email: "jmrush@stthomas.edu",
      //   location: "MSL 333",
      //   img_src: require("../../public/icons-1.3/headshot-JudieRush.jpg"),
      // },
    ],
    DEMO_student_progress_items: [
      {
        name: "Plan (PPDP)",
        progress: {
          complete: 1,
          total: 1,
        },
      },
      {
        name: "Distinct Experiences",
        progress: {
          complete: 2,
          total: 5,
        },
      },
      {
        name: "Debriefings",
        progress: {
          complete: 0,
          total: 2,
        },
      },
      {
        name: "Fieldwork Hours",
        progress: {
          complete: 23,
          total: 30,
        },
      },
    ],
  },
  getters: {
    get_summer_flag: (state) => state.summer,
    get_summer_mentors: (state) => state.summer_mentors,
  },
  mutations: {
    update_summer_mentors(state, mentors) {
      state.summer_mentors = mentors;
    },
    update_selected_term(state, term) {
      state.user_selected_term = term;
    },
    setLoggedIn(state, loggedIn) {
      state.loggedIn = loggedIn;
    },
    toggleSidebar(state) {
      state.sidebar_open = !state.sidebar_open;
    },
    set_groups(state, grps) {
      state.groups = grps;
    },
    login(state, user) {
      if (user.username === "admin") {
        state.loggedIn = true;
        localStorage.setItem("user", user);
        localStorage.setItem("admin", user);
        console.log("login", user.username)
        router.push("/admin").then(() => {
          location.reload();
        });
      } else if (user.username === "user") {
        state.loggedIn = true;
        localStorage.setItem("user", user);
        router.push("/").then(() => {
          location.reload();
        });
      }
    },
    logout(state) {
      localStorage.removeItem("user");
      localStorage.removeItem("lookups");
      localStorage.removeItem("admin");
      if (router.currentRoute.path !== "/login") {
        router.push("/login").then(() => {
          location.reload();
          state.loggedIn = false;
        });
      } else state.loggedIn = false;
    },
    toggle_notification(state, value) {
      state.notification = value;
    },
    set_notification(state, notification) {
      state.notification_content.title = notification.title;
      state.notification_content.message = notification.message;
    },
    toggle_alert(state) {
      state.alert = !state.alert;
    },
    set_sidebar(state, sidebar) {
      state.current_sidebar = sidebar;
    },
    set_students(state, students) {
      students.forEach((students) => {
        state.students.push(students);
      });
    },
    clear_students(state) {
      //state.students = []; THIS DOES NOT WORK, WHY?
      state.students.forEach((students) => {
        // Meanwhile, this works perfectly
        state.students.splice(students, 1);
      });
    },
    set_fieldworks(state, fieldworks) {
      fieldworks.forEach((fieldworks) => {
        state.fieldworks.push(fieldworks);
      });
      // console.log(state.fieldworks);
    },
    clear_fieldworks(state) {
      state.fieldworks.forEach((fieldworks) => {
        state.fieldworks.splice(fieldworks, 1);
      });
    },
    set_summer_flag(state, status) {
      state.summer = status;
    },
  },
  actions: {
    /* Can Be Asynchronous
     * Calls mutations with context.commit('mutation-name')
     * eg. An action can make an API call and await a response,
     *  this response is then sent to a mutator,
     *  this mutator then changes a state variable*/
    fetchResource: async function (
      { state },
      { resource, method, params, body }
    ) {
      state; // lint for the linter god
      return fetch_resource(resource, method, params, body);
    },
  },
  modules: {
    student: student_state,
    lookup_data: lookup_data_store,
    fieldwork_form_store: fieldwork_form_store,
    plan: plan_store,
    milestones: milestone_store,
    masquerade: masquerade_store,
    user_store: user_store,
    level_store: level_store,
  },
});
