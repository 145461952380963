<template>
  <div class="my-0 flex flex-auto items-center order-2 ml-4 md:pl-0">
    <div class="hidden lg:inline text-base inline-block px-4">Grade Level:</div>
    <button
      v-for="item in getStudentLevels"
      :key="item.level"
      :data-cy="`content-navigation-acad-level-${item}`"
      :class="[
        'px-3 inline-block text-xl border-b-4',
        item.selected
          ? 'font-bold text-dark-purple border-purple'
          : item.disabled
          ? 'font-normal text-cool-gray border-cool-white'
          : // Normal
            'font-normal text-dark-gray border-cool-white hover:font-bold hover:border-purple',
      ]"
      @click="setAcadLevel(item)"
      :disabled="item.disabled"
    >
      {{ item.level }}
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "TopbarAcadFilters",
  data() {},
  created() {
    this.getStudentLevels.forEach((l) => {
      if (l.current) {
        this.set_progress_item_totals(l);
      }
    });
  },
  methods: {
    setAcadLevel(level) {
      this.update_acad_level(level);
      this.set_progress_item_totals(level);
    },
    ...mapMutations(["set_progress_item_totals"]),
    ...mapActions(["update_acad_level"]),
  },
  computed: {
    ...mapGetters(["getAcadLevel", "getStudentLevels", "getStudentMaxLevel"]),
  },
};
</script>

<style scoped></style>
