<template>
  <div>
    <div class="secondhead-text">We're here to help!</div>
    <ProgramDirector
      v-for="director in directors"
      :key="director.name"
      :director="director"
    />
  </div>
</template>

<script>
import ProgramDirector from "@/components/displays/student-displays/help/ProgramDirector";
export default {
  name: "HereToHelp",
  components: { ProgramDirector },
  data() {
    return {
      directors: this.$store.state.program_directors,
    };
  },
};
</script>

<style scoped></style>
