<template>
  <div
    class="border-solid border-b border-cool-gray mr-8"
    data-cy="content-header-main-topbar"
  >
    <Disclosure
      as="nav"
      class="flex h-14 relative"
      :class="sidebar_open ? 'ml-48' : 'ml-16'"
    >
      <TopbarGreeting />
      <TopbarAcadFilters
        v-if="
          !$route.path.includes('/admin') &&
          this.$route.matched[0]?.props?.default?.topbarAcademicFilter !== false
        "
      />
      <TopbarDropdown />
    </Disclosure>
  </div>
</template>

<script>
import { Disclosure } from "@headlessui/vue";
import TopbarGreeting from "@/components/navigation/topbar/subcomps/TopbarGreeting";
import TopbarAcadFilters from "@/components/navigation/topbar/subcomps/TopbarAcadFilters";
import TopbarDropdown from "@/components/navigation/topbar/subcomps/TopbarDropdown";

export default {
  name: "Topbar",
  props: {
    sidebar_open: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TopbarDropdown,
    TopbarAcadFilters,
    TopbarGreeting,
    Disclosure,
  },
};
</script>
